<template>
  <div class="exercice_config_proxy">
    <button
        @click="openDialog = true"
    >
      edit
    </button>
    <button
        @click="goToExercice"
    >
      Do it
    </button>
    <Dialog
        :value="openDialog"
    >
      <div class="row">
        <div class="col col-12">
          <div class="row card">
            <div class="col col-12">

              <ComponentProxy
                  v-if="getExerciceConfig !== null"
                  :component="getExerciceConfig.editExercice"
                  :modelValue="modelValue"
                  @update:modelValue="change"
              >
              </ComponentProxy>
            </div>
            <div class="col col-12 col-md-12">
              <button
                  @click="openDialog = false"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>

</template>

<script>
import ExerciceType from '@/ExerciceType';
import ComponentProxy from '@/components/ComponentProxy.vue';
import Dialog from '@/components/Dialog.vue';
export default {
  data() {
    return ({
      openDialog: false,
    });
  },
  components: {
    Dialog,
    ComponentProxy,
  },
  props: {
    instance: {},
    modelValue: {},
  },
  computed: {
    getExerciceConfig() {
      if (
          this.instance !== null
          && this.instance !== undefined
          && this.instance.exercice_type !== null
      ) {
        return (ExerciceType.find((_) => _.name === this.instance.exercice_type));
      }
      return null;
    },
  },
  methods: {
    goToExercice() {
      console.log(this.instance);
      this.$router.push({
        name: 'Exercice',
        params: {
          exercice_id: this.instance.id,
        },
      });
    },
    change(val) {
      this.$emit('update:modelValue', val);
    }
  }
}
</script>

<style lang="scss">
.exercice_config_proxy {
  display: flex;
  align-items: center;
}
</style>