<template>
  <div class="list_selector" tabindex="0">
    <select
      v-model="getModelValue"
    >
      <option
          v-for="item in getList"
          :value="item.value"
          :key="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  data() {
    return ({
      showAdd: false,
    });
  },
  props: {
    list: {},
    modelValue: {},
  },
  computed: {
    getModelValue: {
      get() {
        return (this.modelValue);
      },
      set(selection) {
        this.$emit('update:modelValue', selection);
      }
    },
    getList() {
      return (this.list);
    },
  },
}
</script>

<style lang="scss">
.list_selector {
  position: relative;
  .list_show {
    min-height: 100px;
    padding: 12px;
    border: 1px solid #b88039;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    > div {
      padding: 12px;
      > div {
        background-color: #b88039;
        color: white;
        border-radius: 12px;
        min-height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        cursor: pointer;
      }
    }
  }
  select {
    //display: none;
    //z-index: 1;
    //background: #1b1919;
    //position: absolute;
    width: 100%;
    //left: 0;
    //min-height: 400px;
  }
  &:focus {
    select {
      display: initial;
    }
  }
}
</style>
