import Note from '../Models/Note';

const frequencyStart = 27.5;
const numberOfNotes = 10 * 12;

const noteList = [];
const notesNamesUp = [
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
  'A',
  'A#',
  'B',
];
const notesNamesDown = [
  'C',
  'Db',
  'D',
  'Eb',
  'E',
  'F',
  'Gb',
  'G',
  'Ab',
  'A',
  'Bb',
  'B',
];

const notAltered = [
  0, 2, 4, 5, 7, 9, 11,
];

let lineUp = -1;
let lineDown = 0;
for (let i = 0; i < numberOfNotes; i += 1) {
  const t = i - 21;
  const altered = notAltered.indexOf(i % 12) === -1;
  if (!altered) {
    lineUp += 1;
  }
  noteList.push(new Note(
    i,
    notesNamesUp[i % 12],
    notesNamesDown[i % 12],
    Math.floor((i - 24) / 12),
    frequencyStart * (2 ** (t / 12)),
    altered,
    lineUp,
    lineDown,
  ));
  if (!altered) {
    lineDown += 1;
  }
}

export default noteList;
