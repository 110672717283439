<template>
  <div>
    <NeckTuningSelector
        :model-value="localValue.neckTuning"
        @update:modelValue="set('neckTuning', $event)"
    ></NeckTuningSelector>
    <SoundFondSelect
        :model-value="localValue.playSound"
        @update:modelValue="set('playSound', $event)"
    ></SoundFondSelect>
    {{ localValue.playSound }}
    <button
        v-if="localValue.neckTuning !== null"
        @click="selectAll"
    >
      Tout selectionner
    </button>
    <button
        v-if="localValue.neckTuning !== null"
        @click="unselectAll"
    >
      Tout deselectionner
    </button>
    <GuitarNeck
        v-if="localValue.neckTuning"
        style="min-height: 13vw;"
        :select-mode="true"
        :model-value="localValue.selection"
        @update:modelValue="set('selection', $event)"
        :neck-tuning="localValue.neckTuning"
        showNoteName
    ></GuitarNeck>
  </div>
</template>

<script>
// import LearnGuitarNeckSetting from '@/Exercices/GuitarNeck/Notes/Setting.vue';
import SoundFondSelect from '@/components/SoundFondSelect.vue';
import NeckTuningSelector from '@/MusicalProcessing/Components/NeckTuningSelector.vue';
import GuitarNeck from '@/components/GuitarNeck.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      localValue: {
        selection: [],
        neckTuning: null,
        playSound: null,
      }
    });
  },
  components: {
    GuitarNeck,
    NeckTuningSelector,
    SoundFondSelect,
    // LearnGuitarNeckSetting,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    unselectAll() {
      this.localValue.selection = [];
    },
    selectAll() {
      const toSet = [];
      for (let string = 0; string < this.localValue.neckTuning.notes.length; string += 1) {
        for (let fret = 0; fret < 12; fret += 1) {
          toSet.push({ string: string + 1, fret });
        }
      }
      this.set('selection', toSet);
    },
    set(keyName, value) {
      const toSend = {
        ...this.localValue,
        [keyName]: value,
      };
      this.$emit('update:modelValue', toSend);
    },
    reset() {
      const reset = {
        ...this.localValue,
        ...this.modelValue,
      };
      this.localValue = reset;
    }
  },
  mounted() {
    this.reset();
  },
};
</script>
