<template>
  <div>
    Accordage
    <select
      :value="getValue"
      @input="change($event.target.value)"
    >
      <option
        v-for="item in getList"
        :value="item.value"
        :key="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
import list from '@/MusicalProcessing/Factories/NeckTuning';

export default {
  data() {
    return ({
      selectedIntervals: [],
    });
  },
  props: {
    modelValue: {},
  },
  methods: {
    change(ev) {
      this.$emit('update:modelValue', list[ev]);
    },
  },
  computed: {
    getValue() {
      if (this.modelValue === null) {
        return null;
      }
      return (this.modelValue.name);
    },
    getList() {
      const rt = Object.values(list).map((_) => ({
        text: `${_.name} (${[..._.notes].reverse().map((note) => note.nameUp).join(', ')})`,
        value: _.name,
      }));
      return (rt);
    },
  },
};
</script>
