<template>
  <div>
    <h1>Configuration de l'affichage</h1>
    <bool
        v-model="localValue.show_notes"
        @update:modelValue="set('show_notes', $event)"
        :field="{
          column_name: 'Afficher le nom des notes',
          is_updatable: true,
        }"
    ></bool>
    <label>Nom au centre</label>
    <ListSelector
        :list="listLabels"
        @update:modelValue="set('centerLabel', $event)"
        :model-value="localValue.centerLabel"
    >
    </ListSelector>
    <label>Nom du centre</label>
    <ListSelector
        :list="listLabels"
        @update:modelValue="set('circleLabel', $event)"
        :model-value="localValue.circleLabel"
    >
    </ListSelector>
  </div>
</template>

<script>
import bool from '@/components/FormInput/bool.vue';
import ListSelector from '@/components/SpecificFormInput/ListSelector.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      localValue: {
        show_notes: false,
        centerLabel: 'nameUp',
        circleLabel: 'nameUp',
      },
      listLabels: [
        { text: 'Nom anglais', value: 'name' },
        { text: 'Nom anglais #', value: 'nameUp' },
        { text: 'Nom anglais b', value: 'nameDown' },
        { text: 'Nom français', value: 'nameFr' },
        { text: 'Nom français #', value: 'nameUpFr' },
        { text: 'Nom français b', value: 'nameDownFr' },
        { text: 'Configuration globale', value: 'globalConfig' },
      ],
    });
  },
  components: {
    ListSelector,
    bool,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    set(keyName, value) {
      const toSend = {
        ...this.localValue,
        [keyName]: value,
      };
      this.$emit('update:modelValue', toSend);
    },
    reset() {
      this.localValue = {
        ...this.localValue,
        ...this.modelValue,
      };
    }
  },
  mounted() {
    this.reset();
  },
};
</script>

