<template>
  <div>
<!--    <select :modelValue="getInputValue" @input="send($event.target.value)">-->
    <select v-model="localValue">
      <option :value="null"></option>
      <option v-for="exerciceName in getList" :key="exerciceName" :value="exerciceName">
        {{ exerciceName }}
      </option>
    </select>
  </div>
</template>

<script>
import ExerciceType from '@/ExerciceType';
export default {
  data() {
    return ({
      selectedValue: null,
    });
  },
  props: {
    modelValue: {},
  },
  computed: {
    localValue: {
      get() {
        return (this.modelValue)
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    getList() {
      return (ExerciceType.map((_) => _.name));
    }
  },
}
</script>