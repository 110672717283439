import bool from '@/components/FormInput/bool.vue';
import float from '@/components/FormInput/float.vue';
import text from '@/components/FormInput/text.vue';
import int from '@/components/FormInput/int.vue';
import varchar from '@/components/FormInput/varchar.vue';
import defaultInput from '@/components/FormInput/default.vue';
import jsonb from '@/components/FormInput/jsonb.vue';
import {markRaw} from 'vue';

export default {
    default: markRaw(defaultInput),
    float8: markRaw(float),
    text: markRaw(text),
    int4: markRaw(int),
    _http_method: markRaw(varchar),
    varchar: markRaw(varchar),
    bool: markRaw(bool),
    jsonb: markRaw(jsonb),
}