<template>
  <ComponentProxy
    :component="getComponent(field)"
    :instance="instance"
    :modelValue="modelValue"
    :field="field"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
  </ComponentProxy>
</template>

<script>
import ComponentProxy from '@/components/ComponentProxy.vue';
import FormInput from '@/components/FormInput';
import FormComponent from '@/core/FormComponent';

export default {
  emits: ['update:modelValue'],
  components: {
    ComponentProxy
  },
  data() {
    return ({
      FormInput,
    });
  },
  methods: {
    getComponent(field) {
      if (
        FormComponent[field.table_schema] !== undefined
        && FormComponent[field.table_schema][field.table_name] !== undefined
        && FormComponent[field.table_schema][field.table_name][field.column_name] !== undefined
      ) {
        return (FormComponent[field.table_schema][field.table_name][field.column_name]);
      }
      return (FormInput[field.udt_name])
    },
  },
  props: {
    instance: {},
    modelValue: {},
    field: {},
  }
};
</script>
