<script>
import { h } from 'vue'
export default {
  props: {
    component: Object,
    // statistiques: Object,
  },
  methods: {
    send(event) {

      return (data) => {
        console.log('emit', event);
        this.$emit(event, data)
      };
    },
  },
  render() {  // Utilisation de 'h' à la place de 'createElement'
    const { component, ...restProps } = this.$props;  // Destructuring pour éviter l'utilisation de this

    if (!component) {  // Si 'component' n'est pas fourni, retournez simplement un div.
      return h('div');
    }

    return h(
        component,
        {
          ...restProps,
          on: {
            input: this.send('input'),
            "update:modelValue": this.send('update:modelValue'),
            change: this.send('change'),
            click: this.send('click'),
            close: this.send('close'),
            end: this.send('end'),
            "update:statistiques": this.send('update:statistiques'),
          },
        }
    );
  }
}
</script>
