<template>
  <div v-if="exercice_id !== null">
    <Exercice
        :exercice_id="exercice_id"
        @end="exercice_id = null"
    ></Exercice>
  </div>
  <div class="LearningNodeExercice"
     v-if="data !== null"
  >
    <div class="row">
      <div class="col col-12 col-md-6">
        <div class="row card">
          <div class="col col-12">
            <h2>{{ data.titre }}</h2>
            <EditHtml
              :edit="edit"
              v-model="data.description"
            />
          </div>
          <div class="col col-sm-6 col-12" v-if="edit">
            <EditInt
                :edit="edit"
                v-model="data.distance"
            />

          </div>
          <div class="col col-sm-6 col-12" v-if="edit">
            <EditFloat
                :edit="edit"
                v-model="data.angle"
            />
          </div>
          <div class="col col-12">
            <button
                @click="close()"
            >
              close
            </button>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6">
        <div class="row card">
          <div class="col col-12">
            Liste d'exercices
          </div>
          <div class="col col-12">
           <div class="row exercice-card"
                v-for="ex in getExercicesList"
                :key="ex.id"
           >
             <div class="titre">
               {{ ex.titre }}
             </div>
             <div>
               <button @click="goExercice(ex.id)">Faire l'exercice</button>
             </div>
             <div>
               <button>
                 {{ formatPercentage(ex.score) }}
               </button>
             </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';
import EditHtml from '@/components/FormInput/text.vue';
import EditInt from '@/components/FormInput/int.vue';
import EditFloat from '@/components/FormInput/float.vue';
import Exercice from '@/pages/Exercice.vue';
import { formatPercentage } from '@/Utils/formater'; // Assurez-vous que le chemin est correct


export default {
  components: {
    Exercice,
    EditFloat,
    EditInt,
    EditHtml,
  },
  data() {
    return ({
      uri: '/graph_exercice',
      data: null,
      edit: false,
      exercice_id: null,
    })
  },
  props: {
    modelValue: {},
  },
  computed: {
    getExercicesList() {
      return ([...this.data.exercices_list].sort((a, b) => a.index - b.index));
    },
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reload();
    },
  },
  methods: {
    formatPercentage,
    goExercice(exercice_id) {
      // this.exercice_id = exercice_id;
      this.$router.push({
        name: 'Exercice',
        params: {
          exercice_id,
        },
      });
    },
    close() {
      this.$router.back();
      // this.$emit('update:modelValue', null)
    },
    async reload() {
      if (this.modelValue !== null) {
        const { data } = await axiosInstance.get(`${this.uri}/${this.modelValue}`);
        this.data = data[0];
      }
    }
  },
  async mounted() {
    this.reload();
  },
  beforeUnmount() {
    document.body.classList.remove('no-scroll');
  },
}
</script>

<style lang="scss">
.LearningNodeExercice {
  width: 100%;
  padding-bottom: 30px;
  .exercice-card {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    align-items: center;
    flex-direction: row;
    border-radius: 12px;
    border: 1px solid #c48d77;
    background-color: #00000070;
    overflow: auto;
    .titre {
      flex:1;
      padding: 12px;
    }
    .actions {
      flex: 0 1 auto;
      display: flex;
    }
  }
}
</style>
