<template>
  <main>
    <section class="crud_element">
      <div class="row">
        <div class="col col-12">
          <div class="row card">
            <div class="col col-12">
              <TableData
                :schema="schema"
                :uri="`/CRUD/${tableName}`"
              ></TableData>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import schema from '@/core/Schema';
import TableData from '@/components/TableData.vue';
export default {
  components: {
    TableData,
  },
  data() {
    return ({
      schema: null,
    })
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
  methods: {
  },
  async mounted() {
    this.schema = await schema.get(this.tableName);
  },
}
</script>

<style lang="scss">
.crud_element {
  overflow: scroll;
  height: 100vh;
}
</style>