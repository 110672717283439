<template>
  <div class="multi_selector" tabindex="0">
    <div class="list_show" @click="showAdd = !showAdd">
      <div v-for="elm in getModelValue" :key="elm">
        <div @click.stop="remove(elm)">
          {{ getList[elm]?.text }}
        </div>
      </div>
    </div>
    <select
      multiple
      v-model="getModelValue"
      @click="toggle($event.target._value)"
    >
      <option
          v-for="item in getList"
          :value="item.value"
          :key="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  data() {
    return ({
      showAdd: false,
    });
  },
  props: {
    list: {},
    modelValue: {},
    objectMode: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    remove(toRemove) {
      const toSend = Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      const index = toSend.indexOf(toRemove);

      if (index !== -1) {
        toSend.splice(index, 1);
      }
      this.$emit('update:modelValue', toSend);
    },
    toggle(toToggle) {
      const toSend = Array.isArray(this.modelValue)
        ? this.modelValue
        : [];
      const index = toSend.indexOf(toToggle);
      if (index !== -1) {
        toSend.splice(index, 1);
      } else {
        toSend.push(toToggle);
      }
      this.$emit('update:modelValue', toSend);
      // this.$el.blur();
      // this.showAdd = false;
    },
  },
  computed: {
    getModelValue: {
      get() {
        return (this.modelValue.filter((_) => _ !== undefined));
      },
      set(selection) {
        // le
      }
    },
    getList() {
      return (this.list);
    },
  },
}
</script>

<style lang="scss">
.multi_selector {
  position: relative;
  .list_show {
    min-height: 100px;
    padding: 12px;
    border: 1px solid #b88039;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    > div {
      padding: 12px;
      > div {
        background-color: #b88039;
        color: white;
        border-radius: 12px;
        min-height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        cursor: pointer;
      }
    }
  }
  select {
    display: none;
    z-index: 1;
    background: #1b1919;
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 400px;
  }
  &:focus {
    select {
      display: initial;
    }
  }
}
</style>
