<template>
  <div class="edit_jsonb">
<!--    {{ modelValue }}-->
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    edit: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
  methods: {
    prepareData(numberInput) {
      if (numberInput === null || numberInput === undefined || numberInput.trim() === '') {
        return null;
      }
      return (parseInt(numberInput, 10));
    }
  }
}
</script>

<style lang="scss">
.edit_jsonb {
  position: relative;
  div {
    display: block;
    position: absolute;
    top: -5px;
    left: 24px;
    font-size: 10px;
  }
}
</style>