<template>
  <div class="edit_default">
    <input
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', prepareData($event.target.value))"
        :disabled="!field.is_updatable"
    >
    <div class="label">{{ field.column_name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    edit: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
  methods: {
    prepareData(numberInput) {
      if (numberInput === null || numberInput === undefined || numberInput.trim() === '') {
        return null;
      }
      return (parseInt(numberInput, 10));
    }
  }
}
</script>

<style lang="scss">
.edit_default {
  position: relative;
  div {
    display: block;
    position: absolute;
    top: -5px;
    left: 24px;
    font-size: 10px;
  }
}
</style>