<template>
  <div>
    <svg
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg"
        v-if="numberOfStrings !== 0"
    >
      <!-- Fond du manche -->
      <template
          v-if="!isVertical"
      >
        <defs>
          <filter id="woodpattern" x="0" y="0" width="100%" height="100%">
            <feTurbulence type="fractalNoise" baseFrequency="0.02 0.5"/>
            <feColorMatrix values="0 0 0 .05 .05
                                 0 0 0 .035 .025
                                 0 0 0 .035 .015
                                 0 0 0 0 1"/>
          </filter>
        </defs>
        <rect
            :x="offset" y="0" :width="width - offset" :height="height" filter="url(#woodpattern)" />
        <circle
            v-for="inlay in inlays"
            class="inlay"
            :key="`inlay-${inlay.x}-${inlay.y}`"
            :cx="inlay.x"
            :cy="inlay.y"
            :r="gap / 2"
            fill="#999999ff"
        />
        <template v-for="fret in frets" :key="'fret-' + fret.id">
          <!-- Frettes -->
          <line
              class="fret"
              :x1="fret.position" y1="0" :x2="fret.position" :y2="height"
              stroke="silver" stroke-width="2" />
          <rect
              :class="{
              touche: true,
              selected: selectMode === true
                && selection.some((_) => _.fret === fret.id && _.string === string.id),
              blacked: surbrillance.length > 0 && surbrillance.indexOf(string.id) === -1
            }"
              v-for="string in strings"
              :key="`neck-btn-${string.id}-${fret.id}`"
              :x="fret.previous"
              :y="string.position - gap"
              :width="fret.position - fret.previous"
              :height="gap * 2"
              @keyup.enter="emitStringInfo(string.id)"
              @click="emitStringInfo(string.id, fret.id)"
          />
        </template>
        <circle
            :class="{
            'touche-vide': true,
            selected: selectMode === true
              && selection.some((_) => _.fret === 0 && _.string === string.id)
          }"
            v-for="string in strings"
            :key="`vide-${string.id}`"
            :cx="offset / 2"
            :cy="string.position"
            :r="offset / 2"
            stroke-width="1"
            stroke="black"
            @click="emitStringInfo(string.id, 0)"
            @keyup.enter="emitStringInfo(string.id)"
        />
        <text
            v-for="string in strings"
            :x="offset / 2"
            :y="string.position"
            :key="`note-label-${string.id}-0`"
            text-anchor="middle"
            fill="white"
            :class="getNoteInformation(string.id, 0)[1] == '#' ? 'note-std' : 'note-bigger'"
            transform="translate(0 -2)"
        >
          {{ getNoteInformation(string.id, 0) }}
        </text>
        <template v-for="string in strings" :key="'string-' + string.id">
          <!-- Cordes -->
          <line
              :class="{
              string: true,
              unsurbrillance: surbrillance.length > 0 && surbrillance.indexOf(string.id) === -1
            }"
              x1="0"
              :y1="string.position"
              :x2="width"
              :y2="string.position"
              :stroke-width="0.5 + (string.id / 3)"
          />
          <line
              :class="{
              'string-over': true,
              unsurbrillance: surbrillance.length > 0 && surbrillance.indexOf(string.id) === -1
          }"
              x1="0"
              :y1="string.position - ((0.5 + (string.id / 3)) / 4)"
              :x2="width"
              :y2="string.position - ((0.5 + (string.id / 3)) / 4)"
              :stroke-width="(0.5 + (string.id / 3)) / 4"
          />
          <text
              v-for="fret in frets"
              :x="fret.previous + ((fret.position - fret.previous) / 2)"
              :y="string.position"
              :key="`note-label-${string.id}-${fret.id}`"
              text-anchor="middle"
              fill="white"
              :class="getNoteInformation(string.id, fret.id)[1] == '#' ? 'note-std' : 'note-bigger'"
              transform="translate(0 -2)"
          >
            {{ getNoteInformation(string.id, fret.id) }}
          </text>
        </template>
      </template>
      <template
          v-else
      >
        <defs>
          <filter id="woodpattern-vertical" x="0" y="0" width="100%" height="100%">
            <feTurbulence type="fractalNoise" baseFrequency="0.5 0.02"/>
            <feColorMatrix values="0 0 0 .05 .05
                                   0 0 0 .035 .025
                                   0 0 0 .035 .015
                                   0 0 0 0 1"/>
          </filter>
        </defs>
        <rect x="0" :y="offset" :width="width" :height="height - offset"
              filter="url(#woodpattern-vertical)" />
        <circle
            v-for="inlay in inlays"
            class="inlay"
            :key="`inlay-${inlay}`"
            :cx="inlay.y"
            :cy="inlay.x"
            :r="gap / 2"
            fill="#999999ff"
        />
        <template v-for="fret in frets" :key="'fret-' + fret.id">
          <!-- Frettes -->
          <line
              class="fret"
              x1="0" :y1="fret.position" :x2="width" :y2="fret.position"
              stroke="silver" stroke-width="2" />
          <rect
              :class="{
              touche: true,
              blacked: surbrillance.length > 0
              && surbrillance.indexOf(1 + numberOfStrings - string.id) === -1
            }"
              v-for="string in strings"
              :key="`neck-btn-${1 + numberOfStrings - string.id}-${fret.id}`"
              :x="string.position - gap"
              :y="fret.previous"
              :width="gap * 2"
              :height="fret.position - fret.previous"
              @keyup.enter="emitStringInfo(1 + numberOfStrings - string.id)"
              @click="emitStringInfo(1 + numberOfStrings - string.id, fret.id)"
          />
        </template>
        <circle
            class="touche-vide"
            v-for="string in strings"
            :key="`vide-${1 + numberOfStrings - string.id}`"
            :cx="string.position"
            :cy="offset / 2"
            :r="offset / 2"
            stroke-width="1"
            stroke="black"
            @click="emitStringInfo(1 + numberOfStrings - string.id, 0)"
            @keyup.enter="emitStringInfo(1 + numberOfStrings - string.id)"
        />
        <!-- Cordes -->
        <line
            v-for="string in strings"
            :class="{
            string: true,
            unsurbrillance: surbrillance.length > 0
            && surbrillance.indexOf(1 + numberOfStrings - string.id) === -1
          }"
            :key="'string-' + string.id"
            y1="0"
            :x1="string.position"
            :y2="height"
            :x2="string.position"
            :stroke-width="(0.5 + ((1 + numberOfStrings - string.id) / 3))"
        />
      </template>
    </svg>
  </div>
</template>

<script>
import NeckTuning from '@/MusicalProcessing/Factories/NeckTuning';
import Notes from '@/MusicalProcessing/Factories/Notes';

export default {
  props: {
    showNoteName: {
      type: Boolean,
      default: false,
    },
    surbrillance: {
      type: Array,
      default() {
        return ([]);
      },
    },
    modelValue: {},
    orientation: {
      type: String,
      default: 'vertical', // ou 'vertical'
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
    numberOfFrets: {
      type: Number,
      default: 12,
    },
    neckTuning: {
      default() {
        return (NeckTuning.StandardTuning);
      },
    },
    selectMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      resizeObserver: null,
      diapason: 600,
      width: 1300,
      height: 150,
      selection: [],
    };
  },
  computed: {
    numberOfStrings() {
      if (this.neckTuning === null) {
        return (0);
      }
      return (this.neckTuning.notes.length);
    },
    getModelValue() {
      return (this.modelValue);
    },
    offset() {
      return (this.gap * 2);
    },
    isVertical() {
      // return this.orientation === 'vertical';
      return (this.height > this.width);
    },
    frets() {
      const size = (this.isVertical ? this.height : this.width) - this.offset;
      let fretsArray = [];
      let previous = 0;
      for (let i = 1; i <= this.numberOfFrets; i += 1) {
        const position = (1 - (1 / 2 ** (i / 12)));
        fretsArray.push({
          id: i,
          position,
          previous,
        });
        previous = position;
      }
      const max = fretsArray[this.numberOfFrets - 1].position;
      fretsArray = fretsArray.map((_) => ({
        ..._,
        position: ((size * _.position) / max) + this.offset,
        previous: ((size * _.previous) / max) + this.offset,
      }));
      return fretsArray;
    },
    gap() {
      const gap = (this.isVertical
              ? this.width / (this.numberOfStrings)
              : this.height / (this.numberOfStrings))
          / 2;
      return (gap);
    },
    inlays() {
      const pos = [
        3,
        5,
        7,
        9,
        12,
        15,
        17,
        19,
        21,
        24,
      ];
      const rt = pos.reduce((acc, _) => {
        if (this.frets[_ - 1] === undefined) {
          return (acc);
        }
        if ([12, 24].indexOf(_) !== -1) {
          const fret = this.frets[_ - 1];
          acc.push({
            x: fret.position - (fret.position - fret.previous) / 2,
            y: this.gap * this.numberOfStrings * 1.5,
          });
          acc.push({
            x: fret.position - (fret.position - fret.previous) / 2,
            y: this.gap * (this.numberOfStrings / 2),
          });
        } else {
          const fret = this.frets[_ - 1];
          acc.push({
            x: fret.position - (fret.position - fret.previous) / 2,
            y: this.gap * this.numberOfStrings,
          });
        }
        return (acc);
      }, []);
      return (rt);
    },
    strings() {
      const stringsArray = [];
      const total = 2 * this.numberOfStrings;
      let stringId = 1;
      for (let i = 1; i <= total; i += 2) {
        stringsArray.push({ id: stringId, position: this.gap * i });
        stringId += 1;
      }
      return stringsArray;
    },
  },
  watch: {
    isVertical(isVertical) {
      this.$emit('isVertical', isVertical);
    },
    getModelValue(value) {
      this.selection = value;
    },
  },
  methods: {
    getNoteInformation(stringId, fretId) {
      if (this.showNoteName !== true) {
        return ('');
      }
      const baseNote = this.neckTuning.notes[stringId - 1];
      return (Notes[baseNote.midiNote + fretId].nameUp);
    },
    emitStringInfo(stringId, fretId) {
      this.$emit('stringClicked', { stringId, fretId });
      // if (Array.isArray(this.selection)) {
      let selection = [
        ...this.selection,
      ];
      if (selection.some((_) => _.fret === fretId && _.string === stringId)) {
        selection = selection.filter((_) => _.fret !== fretId || _.string !== stringId);
      } else {
        selection.push({ fret: fretId, string: stringId });
      }

      this.selection = selection;
      this.$emit('update:modelValue', selection);
      // }
    },
    resetSize() {
      const rect = this.$el.parentElement.getBoundingClientRect();
      this.width = rect.width;
      this.height = rect.height;
    },
  },
  mounted() {
    this.resetSize();
    this.resizeObserver = new ResizeObserver(() => {
      this.width = 1;
      this.height = 1;
      this.$nextTick(() => {
        this.resetSize();
      });
    });
    this.resizeObserver.observe(this.$el.parentElement);
    if (this.modelValue !== undefined) {
      // this.$emit('update:modelValue', []);
      this.selection = this.modelValue;
    } else {
      this.$emit('update:modelValue', []);
    }
  },
  beforeUnmount() {
    this.resizeObserver.unobserve(this.$el.parentElement);
  },
};
</script>

<style>

.touche {
  fill: #ffffff00;
  transition: all 70ms ease-in-out;
}

.touche:hover {
  fill: #ffffff42;
  cursor: pointer;
}
.touche.selected {
  fill: #ddffdd84;
}
.touche-vide {
  fill: #00000000;
  transition: all 70ms ease-in-out;
}

.touche-vide:hover {
  fill: #00000042;
  cursor: pointer;
}

.touche-vide.selected {
  fill: #00880084;
}
.touche.blacked {
  fill: #00000042;
}

.string {
  pointer-events: none;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  stroke: #68615b;
}
.string-over {
  pointer-events: none;
  filter: drop-shadow(0px 1px 0.5px rgba(255, 255, 255, 1));
  stroke: #ffffffe3;
}
.fret {
  filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.7))
}
.unsurbrillance {
  opacity: 0.3;
}
.note-std {
  font-size: 14px;
  font-weight: normal;
  opacity: 0.4;
  fill:#5555ff;
  pointer-events: none;
}
.note-bigger {
  font-size: 18px;
  font-weight: bold;
  fill:#5555ff;
  pointer-events: none;
}
</style>
