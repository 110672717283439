import Notes from '@/MusicalProcessing/Factories/Notes';
import NeckTuning from '@/MusicalProcessing/Models/NeckTuning';

export default {
  StandardTuning: new NeckTuning('StandardTuning', [
    Notes[100],
    Notes[95],
    Notes[91],
    Notes[86],
    Notes[81],
    Notes[76],
  ]),
  StandardBassTuning: new NeckTuning('StandardBassTuning', [
    Notes[91],
    Notes[86],
    Notes[81],
    Notes[76],
  ]),
};
