<template>
  <div class="background_container">
    <transition name="fade">
      <img :src="currentImage" :key="currentIndex" alt="Background" class="background_image"/>
    </transition>
  </div>
</template>

<script>
import interval1 from '@/assets/Images/interval1.webp';
import learn_neck from '@/assets/Images/learn_neck.webp';
import ear1 from '@/assets/Images/ear1.webp';
import piano from '@/assets/Images/piano.webp';
import solfege2 from '@/assets/Images/solfege2.webp';
import rythme from '@/assets/Images/rythme.webp';
import guitare from '@/assets/Images/guitare.webp';
import notes from '@/assets/Images/notes.webp';
import accords from '@/assets/Images/accords.webp';
export default {
  data() {
    return {
      images: {
        17: notes,
        5: interval1,
        4: learn_neck,
        7: learn_neck,
        8: learn_neck,
        11: learn_neck,
        2: ear1,
        13: ear1,
        15: ear1,
        16: ear1,
        3: piano,
        1: solfege2,
        12: solfege2,
        18: rythme,
        10: guitare,
        19: guitare,
        6: accords,
        9: accords,
      },
    };
  },
  props: {
    currentIndex: {},
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    }
  },
}
</script>

<style>
.background_container {
  width: 100%;
  height: 100vh; /* ou toute autre hauteur nécessaire */
  position: fixed;
  opacity: 0.7;
  z-index: -1;
}

.background_image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image couvre bien tout l'espace */
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease; /* Durée et style de transition */
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
