import {toRaw} from 'vue';
import Notes from '@/MusicalProcessing/Factories/Notes';

export default class PerformanceEvaluator {
    constructor(possibilities, oldStat) {
        this.possibilities = possibilities;
        this.minimumScore = 0.65;
        this.minimumCount = 1;
        this.combinaison = this.generateCombinations();
        if (oldStat) {
            this.tableStat = oldStat;
            this.initCheckEnabled();
        }
        else
            this.tableStat = this.generateTableStat();
        this.checkEnabled();
        this.combinaison[0].enabled = true;
        this.actualQuestionIndex = null;
        this.baseTimeEqualOne = 6000;
        // this.baseTimeEqualOne = 250;
        this.lastTimeKeyed = null;
        this.errorCount = null;
        this.successCount = null;
        this.errorList = [];
        this.score = {point: 0, count: 0};
        this.normalizedWeight = [];
        this.calcScore();
        this.setNewOnError = true;
        this.keysList = this.getTableStatKeysList();
        this.checkMethod = (response, actual) => this.keysList.every((_) => response[_] === actual[_]);
    }

    getGlobalScore() {
        return (((this.score.point / this.score.count) * this.getEnabledCombinaisons().length) / this.combinaison.length);
    }

    print() {
        // return;
        console.log(this.tableStat);
        // console.table(Object.fromEntries(Object.entries(this.tableStat).map(([key, value]) => {
        //     const rt = Object.fromEntries(Object.entries(value).map(([key2, _]) => {
        //         if (_.count === 0) {
        //             return ([key2, '']);
        //         }
        //         const round = Math.round(_.point * 10) / 10;
        //         return ([key2, `${round}/${_.count}`]);
        //     }));
        //     return ([Notes[key].nameUp, rt]);
        // })));
    }
    getToSave() {
        return ({
            tableStat: this.tableStat,
            score: this.getGlobalScore(),
            // score: ((this.score.point / this.score.count) * this.getEnabledCombinaisons.length) / this.combinaison.length
        })
    }

    setCheckMethod(checkMethod) {
        this.checkMethod = checkMethod;
    }

    calcScore() {
        function recursiveGetScore(cursor) {
            if (cursor.count !== undefined && cursor.point !== undefined) {
                return (cursor);
            }
            return (Object.values(cursor).reduce((acc, elm) => {
                const subValue = recursiveGetScore(toRaw(elm));
                return ({
                    point: acc.point + subValue.point,
                    count: acc.count + subValue.count,
                })
            }, {point: 0, count: 0}));
        }
        this.score = recursiveGetScore(toRaw(this.tableStat));
    }
    getAllCombinaisons() {
        return (this.combinaison);
    }
    getEnabledCombinaisons() {
        return (this.combinaison.filter((_) => _.enabled));
    }

    getTableStatKeysList() {
        return (Object.keys(this.possibilities).sort());
    }

    generateTableStat() {
        const keysList = this.getTableStatKeysList();
        let enabled = true;
        const tmp = (function recursiveGenerateStat(keysList, objectContent) {
            const rt = {};
            const actualKey = keysList.shift();

            objectContent[actualKey].forEach((_) => {
                const serializedlKey = JSON.stringify(_);
                if (keysList.length) {
                    rt[serializedlKey] = recursiveGenerateStat([...keysList], objectContent);
                } else {
                    rt[serializedlKey] = {
                        count: 0,
                        point: 0,
                        enabled,
                    };
                    enabled = false;
                }
            })
            return (rt);
        })(keysList, this.possibilities);
        return (tmp);
    }

    generateCombinations() {
        const modelValue = this.possibilities;
        let combinations = [];
        let axes = Object.keys(modelValue);

        function buildCombinations(index, currentCombination) {
            if (index === axes.length) {
                combinations.push([...currentCombination]);
                return;
            }

            let axis = axes[index];
            let values = modelValue[axis];
            for (let i = 0; i < values.length; i++) {
                buildCombinations(index + 1, [...currentCombination, { axis: axis, value: values[i], index: i }]);
            }
        }
        // Initialiser la génération des combinaisons
        buildCombinations(0, []);


        // Trier les combinaisons basées sur la somme de leurs indices
        combinations.sort((a, b) => {
            let sumA = a.reduce((sum, item) => sum + item.index, 0);
            let sumB = b.reduce((sum, item) => sum + item.index, 0);
            return sumA - sumB;
        });
        // Optionnel : Transformer les combinaisons pour enlever les détails d'index, etc.
        let simplifiedCombinations = combinations.map(combination =>
            combination.reduce((obj, item) => ({ ...obj, [item.axis]: item.value, enabled: false }), {})
        );
        if (simplifiedCombinations.length) {
            simplifiedCombinations[0].enabled = true;
        }
        return simplifiedCombinations;
    }

    getOneStat(toGet) {
        const stat = this.tableStat;
        const keysList = this.getTableStatKeysList();
        let cursor = stat;

        keysList.forEach((_) => {
            const key = JSON.stringify(toGet[_]);
            cursor = cursor[key];
        })
        return (cursor);
    }
    setOneStat(toSet, value) {
        const stat = this.tableStat;
        const keysList = this.getTableStatKeysList();
        let cursor = stat;

        keysList.forEach((_) => {
            const key = JSON.stringify(toSet[_]);
            cursor = cursor[key];
        });
        cursor = value;
    }

    getWeightFor(toGet) {
        const actualTraining = this.getEnabledCombinaisons();

        const keysList = this.getTableStatKeysList();
        const index = actualTraining.findIndex((_) => {
            return (keysList.every((keyName) => _[keyName] === toGet[keyName]));
        });
        if (index === -1) {
            return null;
        }
        return (this.normalizedWeight[index]);
    }
    setNew() {
        const actualTraining = this.getEnabledCombinaisons();
        const maxCount =  actualTraining.reduce((acc, _) => {
            const stat = this.getOneStat(_);
            return (Math.max(acc, stat.count));
        }, 1);

        const weights = actualTraining.map((_, index) => {
            if (this.actualQuestionIndex === index)
                return (0);
            const stat = this.getOneStat(_);
            let actualPoint = stat.count - stat.point;
            if (stat.count > 0 && (stat.point / stat.count <= this.minimumScore))
                actualPoint = actualPoint ** 2;
            actualPoint = actualPoint ** 2;
            actualPoint += (maxCount - stat.count) / 2;
            return (actualPoint);
        });

        const minWeight = Object.values(weights).reduce((a, b) => a > b ? b : a, 0);

        const uppedWeight = weights.map((_) => _ + minWeight);

        const totalWeight = Object.values(uppedWeight).reduce((a, b) => a + b, 0);
        this.print();
        this.normalizedWeight = uppedWeight.map((_) => (_) / totalWeight);

        let i = 0, sum = 0, r = Math.random();
        for (i in this.normalizedWeight) {
            sum += this.normalizedWeight[i];
            if (r <= sum) {
                this.actualQuestionIndex = parseInt(i, 10);
                break;
            }
        }
        const choose = actualTraining[this.actualQuestionIndex];
        this.getOneStat(choose).count += 1;

        this.lastTimeKeyed = Date.now();
        return choose;
    }
    setNewOld() {
        // Cette fonction doit retourner un des élements dans combinaison qui est activé
        // En fonction des statistiques
        const actualTraining = this.getEnabledCombinaisons();

        // L'inverse du nombre de choix possibles
        const toAdd = 1 / actualTraining.length;

        // Attribution de poids pour chaque possibilités
        const weights = actualTraining.map((_) => {
            const stat = this.getOneStat(_);
            if (stat.count === 0) {
                return (1);
            }
            // (toAdd / 2) +
            return ((stat.count - stat.point) / (stat.count ** 1.5) ** 2);
        });
        // Poids le plus petit
        const minWeight = Object.values(weights).reduce((a, b) => a > b ? b : a, 0);

        // On remonte le tout pour ne pas avoir de valeure négatives
        const uppedWeight = weights.map((_) => _ + minWeight);

        // Calcul du total pour la normalisation
        const totalWeight = Object.values(uppedWeight).reduce((a, b) => a + b, 0);

        // normalisation des poids pour avoir une proportion de sortie
        this.normalizedWeight = uppedWeight.map((_) => (_) / totalWeight);
        // console.log(weights);
        // console.log(this.normalizedWeight);
        const indexLastQuestion = this.actualQuestionIndex;

        // On pioche avec random dans la liste en evitant un doublon avec la question précedente
        let limitCounter = 100;
        while (indexLastQuestion === this.actualQuestionIndex && limitCounter-- > 0) {
            this.actualQuestionIndex = null;
            let i = 0, sum = 0, r = Math.random();
            for (i in this.normalizedWeight) {
                sum += this.normalizedWeight[i];
                if (r <= sum) {
                    this.actualQuestionIndex = parseInt(i, 10);
                    break;
                }
            }
        }
        const choose = actualTraining[this.actualQuestionIndex];
        this.getOneStat(choose).count += 1;
        return choose;
    }


    initCheckEnabled() {
        const combinaisons = this.combinaison;
        let enabled = false;
        this.combinaison = [...combinaisons]
            .reverse()
            .map((_) => {
                const stat = this.getOneStat(_);
                if (
                    stat.point > 0
                ) {
                    enabled = true;
                }
                const rt = {
                    ..._,
                    enabled: enabled || _.enabled,
                }
                return (rt);
            }).reverse()
    }
    checkEnabled() {
        const combinaisons = this.combinaison;
        let enabled = true;
        this.combinaison = combinaisons.map((_) => {
            const stat = this.getOneStat(_);
            const rt = {
                ..._,
                enabled: enabled || _.enabled,
            }
            if (
                stat.count < this.minimumCount
                || (stat.point / stat.count) < this.minimumScore
            ) {
                enabled = false;
            }
            return (rt);
        })
    }

    getPointPotential() {
        const current = Date.now();
        let point = this.baseTimeEqualOne / (current - this.lastTimeKeyed);
        return (point);
    }
    doResponse(response) {
        const current = Date.now();
        let point = this.getPointPotential();

        const actualQuestion = this.getActualQuestion();
        const check = this.checkMethod(response, actualQuestion);
        if (!check) {
            if (point > 1)
                point = 1;
            this.lastTimeKeyed = current;
            this.errorCount += 1;
            this.getOneStat(response).point  -= point;
            this.getOneStat(actualQuestion).point  -= point;
            this.errorList.push(response);
            if (this.setNewOnError) {
                this.setNew();
            }
            this.calcScore();
            return (false);
        } else {
            this.lastTimeKeyed = current;
            this.successCount += 1;
            const actualStat = this.getOneStat(actualQuestion);
            // console.log(`+${point}`);
            actualStat.point  += point;
            if (actualStat.point > actualStat.count) {
                actualStat.point = actualStat.count;
            }
            this.errorList = [];
            this.checkEnabled();
            this.setNew();
            this.calcScore();
            return (true);
        }
    }

    getActualQuestion() {
        const actualTraining = this.getEnabledCombinaisons();
        return actualTraining[this.actualQuestionIndex];
    }

    /**
     * Cette fonction permet de figer
     * par exemple si on travail sur 2 axes interval et notes.
     * On peut lui donner {note: 84} de manière à ce que la vérification se porte uniquement sur
     * les intervals
     */
    setScope() {

    }
}