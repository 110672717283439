<template>
  <div class="edit_html">
    <div
      v-if="field.is_updatable"
    >
      <ckeditor
          :editor="editor"
          :modelValue="getValue"
          :config="editorConfig"
          @input="change"
      >
      </ckeditor>
    </div>
    <div class="show" v-html="modelValue" v-if="!edit" @click="toggle"></div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  data() {
    return {
      // edit: false,
      editor: ClassicEditor,
      editorData: '<p></p>',
      editorConfig: {
        // The configuration of the editor.
      }
    };
  },
  props: {
    modelValue: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
    instance: {},
  },
  computed: {
    getValue() {
      if (this.modelValue === undefined || this.modelValue === null) {
        return ('');
      }
      return (this.modelValue);
    },
  },
  methods: {
    change(ev) {
      this.$emit('update:modelValue', ev);
    },
  },
  mounted() {
    if (this.modelValue === null) {
      this.$emit('update:modelValue', '<p></p>');
    }
  }
};
</script>

<style lang="scss">
.edit_html {
  //.show {
  //  border: 1px solid transparent;
  //  margin: -1px;
  //  transition: border-color 100ms ease;
  //  &:hover {
  //    border: 1px solid #ffffff21;
  //  }
  //}
  .bottom_tool {
    background: #ffffff21 !important;
    border-radius: 0 0 10px 10px;
    border: 1px solid #ffffff21;
    height: 30px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .ck {
    /* Cibler le conteneur principal de l'éditeur */
    &.ck-editor {
      background: transparent !important;
      //margin-left: -10px;
      //margin-right: -10px;
      //margin-top: -37.68px;
      //margin-top: -38.82px;
    }

    /* Cibler le contenu de l'éditeur */
    &.ck-content {
      background: transparent !important;
    }
    &.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
    &.ck-toolbar {
      background: #ffffff21 !important;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff21;
      * {
        color: white;
      }
    }

    &.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
    &.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners,
    &.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      //border-radius: 0 0 10px 10px;
      border-radius: 0;
      border: 1px solid #ffffff21;
    }

    .ck .ck-insert-table-dropdown-grid-box.ck-on,
    .ck.ck-list {
      background: black;
    }

  }
}
</style>