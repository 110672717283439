<template>
  <div class="full-screen-show">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.full-screen-show {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 75px;
  color: #55ff99;
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
  1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  z-index: 2;
  opacity: 0.4;
}

</style>
