<template>
  <div
      class="edit_bool" :class="{ checked: modelValue}"
  >
    <div class="bool_label">{{ field.column_name }}</div>
    <input
        type="checkbox"
        :checked="modelValue"
        @click="$emit('update:modelValue', !modelValue)"
        :disabled="!field.is_updatable || field.column_name === 'id'"
    >
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    edit: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
  methods: {
    prepareData(boolInput) {
      // return (true);
      return (boolInput === 'on');
      // if (numberInput === null || numberInput === undefined || numberInput.trim() === '') {
      //   return null;
      // }
      // return (parseInt(numberInput, 10));
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.edit_bool {
  position: relative;
  min-height: 32px;
  border: 1px solid $primary-color;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    height: 54px;
    cursor: pointer;
    width: 100%;
    min-height: 32px;
    position: absolute;
    left: 0;
    top: 0;
  }
  &.checked {
    background-color: $primary-color;
    .bool_label {
      color: black;
    }
  }
  //div {
  //  display: block;
  //  position: absolute;
  //  top: -5px;
  //  left: 24px;
  //  font-size: 10px;
  //}
}
</style>