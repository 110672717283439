<template>
  <div class="music_clock_learn" v-if="performanceEvaluator !== null">
    <div class="line score">
      <div>
        <span v-if="performanceEvaluator.score.count > 0">
          {{ Math.round(10000 * performanceEvaluator.getGlobalScore()) / 100 }} %
          <span class="small_score">
            ({{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %)
          </span>
        </span>
      </div>
      <div>
        {{ getNote(performanceEvaluator.getActualQuestion()?.note)[getCenterLabel] }}
      </div>
    </div>
    <MusicClock
        @note="check"
        :errors="performanceEvaluator.errorList.map((_) => _.note)"
        :score="getScore"
        :show-notes-name="modelValue.show_notes"
        :note-label="getCircleLabel"
    >
      <div class="note_show">
        {{ getNote(performanceEvaluator.getActualQuestion()?.note)[getCenterLabel] }}
      </div>
    </MusicClock>
  </div>
</template>
<script>
import Notes from '@/MusicalProcessing/Factories/Notes';
import Interval from '@/MusicalProcessing/Factories/Interval';
import SimpleSynth from '@/core/simpleSynth';
import PerformanceEvaluator from '@/core/PerformanceEvaluator';
import MusicClock from '@/components/MusicClock.vue';

export default {
  components: {
    MusicClock
  },
  props: {
    modelValue: {},
    statistiques: {},
  },
  data() {
    return ({
      pointPotential: null,
      response: '',
      exerciceConfig: {
        note: [],
      },
      notePresentation: [],
      intervalList: Interval,
      currentInterval: null,
      noteIntervalDuration: 500,
      playNote: null,
      playNoteInterval: null,
      minimumScore: 0.6,
      actualTraining: [],
      indexNextQuestion: null,
      lastTimeKeyed: null,
      baseTimeEqualOne: 125, // 1 seconde donne un point,
      saveIntervalTime: 1000, // 10 seconde
      saveIntervalInstance: null,
      performanceEvaluator: null,
    })
  },
  computed: {
    getCenterLabel() {
      return (this.modelValue.centerLabel ?? 'nameUp');
    },
    getCircleLabel() {
      return (this.modelValue.circleLabel ?? 'nameUp');
    },
    getScore() {
      return (Object.fromEntries(
        Object.entries(this.performanceEvaluator.tableStat)
        .map(([key, _]) => [key, _.point / _.count])
      ));
    },
  },
  methods: {
    getNote(noteIndex) {
      return (Notes[noteIndex]);
    },
    check(note) {
      const response = {
        note: note.midiNote,
      }
      const success = this.performanceEvaluator.doResponse(response);
      const toSave = this.performanceEvaluator.getToSave();
      this.$emit('update:statistiques', toSave);
      this.play(note, success ? 1 : 3);
    },
    play(note, octave) {
      const playNote = `${note.nameDown}${note.octave - octave}`;
      SimpleSynth.playNoteName(playNote);
    },
    isNoteError(note) {
      return (this.performanceEvaluator.errorList.some((_) => _.note === note));
    },
    isIntervalDisabled(interval) {
      return (!this.performanceEvaluator.getEnabledCombinaisons().some((_) => _.interval === interval));
    },
  },
  beforeUnmount() {
    window.clearInterval(this.saveIntervalInstance);
  },
  async mounted() {
    this.notePresentation = Notes
        .filter((_) => {
          return (
              _.octave === 4
          );
        })
        .map((_) => _.midiNote)
    ;
    this.exerciceConfig.note = [
      5, 0, 7, 2, 9, 4, 11, 6, 1, 8, 3, 10
    ].map((_) => _ + 72);
    this.performanceEvaluator = new PerformanceEvaluator(this.exerciceConfig, this.statistiques);
    this.performanceEvaluator.baseTimeEqualOne = 1000;

    this.performanceEvaluator.setNew();
    this.performanceEvaluator.setNewOnError = false;

    setInterval(() => {
      const tmp = this.performanceEvaluator.getPointPotential();
      const actual = this.performanceEvaluator.getActualQuestion();
      const needed = actual.count - actual.point;
      const pointPotential = tmp > needed ? needed : tmp;
      // this.pointPotential = pointPotential /  this.performanceEvaluator.getAllCombinaisons().length;
      this.pointPotential = pointPotential;
    }, 100);
  }
};
</script>

<style lang="scss">
.music_clock_learn {
  display:flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  height: 100%;

  .note_show {
    color: white;
    font-size: 40px;
    font-weight: bold;
  }

  > div.line {
    font-weight: bold;
    &.score {
      background-color: black;
      color: white;
    }
    display:flex;
    width: 100%;
    justify-content: stretch;
    flex: 1;
    > div {
      flex: 1;
      font-size: 22px;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &.error {
          background-color: #ff000047;
        }
        border-radius: 0;
      }
      align-items: center;
      justify-content: center;
      display:flex;
    }
  }
  .score {
    font-size: 24px;
    text-align: center;
    .small_score {
      font-size:0.7em;
      font-style: italic;
      font-weight: normal;
    }
  }
}
</style>