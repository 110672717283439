import ExerciceType from '@/components/SpecificFormInput/ExerciceType.vue';
import ExerciceConfigProxy from '@/components/SpecificFormInput/ExerciceConfigProxy.vue';
import {markRaw} from 'vue';

export default {
    pgs_model: {
        learning_node: {
            // angle: markRaw(text),
        },
        learning_node_exercice: {
            exercice_type: markRaw(ExerciceType),
            setting: markRaw(ExerciceConfigProxy),
        },
    },
};