<template>
  <div>
    <h1>Configuration de l'exercice des notes Acendantes / Descendantes</h1>
    <bool
        v-model="localValue.enable_altered"
        @update:modelValue="set('enable_altered', $event)"
        :field="{
          column_name: 'Inclure les notes altérées',
          is_updatable: true,
        }"
    ></bool>
    <bool
        v-model="localValue.enable_desc"
        @update:modelValue="set('enable_desc', $event)"
        :field="{
          column_name: 'Inclure les interval descendant',
          is_updatable: true,
        }"
    ></bool>
    <bool
        v-model="localValue.clock_mode"
        @update:modelValue="set('clock_mode', $event)"
        :field="{
          column_name: 'Mode Horloge',
          is_updatable: true,
        }"
    ></bool>
    <MultiSelector
        :list="list"
        @update:modelValue="set('semitone', $event)"
        :model-value="localValue.semitone"
    >
    </MultiSelector>
    <template v-if="localValue.clock_mode">
      <bool
          v-model="localValue.show_notes"
          @update:modelValue="set('show_notes', $event)"
          :field="{
            column_name: 'Afficher le nom des notes',
            is_updatable: true,
          }"
      ></bool>
      <label>Nom au centre</label>
      <ListSelector
          :list="listLabels"
          @update:modelValue="set('centerLabel', $event)"
          :model-value="localValue.centerLabel"
      >
      </ListSelector>
      <label>Nom du centre</label>
      <ListSelector
          :list="listLabels"
          @update:modelValue="set('circleLabel', $event)"
          :model-value="localValue.circleLabel"
      >
      </ListSelector>
    </template>
  </div>
</template>

<script>
import bool from '@/components/FormInput/bool.vue';
import MultiSelector from '@/components/SpecificFormInput/MultiSelector.vue';
import ListSelector from '@/components/SpecificFormInput/ListSelector.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      localValue: {
        enable_altered: false,
        enable_desc: false,
        clock_mode: false,
        semitone: [],
        show_notes: false,
        centerLabel: 'nameUp',
        circleLabel: 'nameUp',
      },
      listLabels: [
        { text: 'Nom anglais', value: 'name' },
        { text: 'Nom anglais #', value: 'nameUp' },
        { text: 'Nom anglais b', value: 'nameDown' },
        { text: 'Nom français', value: 'nameFr' },
        { text: 'Nom français #', value: 'nameUpFr' },
        { text: 'Nom français b', value: 'nameDownFr' },
        { text: 'Configuration globale', value: 'globalConfig' },
      ],
      list: [
        { text: '- 12', value: 0 },
        { text: '- 11', value: 1 },
        { text: '- 10', value: 2 },
        { text: '- 9', value: 3 },
        { text: '- 8', value: 4 },
        { text: '- 7', value: 5 },
        { text: '- 6', value: 6 },
        { text: '- 5', value: 7 },
        { text: '- 4', value: 8 },
        { text: '- 3', value: 9 },
        { text: '- 2', value: 10 },
        { text: '- 1', value: 11 },
        { text: '0', value: 12 },
        { text: '+ 1', value: 13 },
        { text: '+ 2', value: 14 },
        { text: '+ 3', value: 15 },
        { text: '+ 4', value: 16 },
        { text: '+ 5', value: 17 },
        { text: '+ 6', value: 18 },
        { text: '+ 7', value: 19 },
        { text: '+ 8', value: 20 },
        { text: '+ 9', value: 21 },
        { text: '+ 10', value: 22 },
        { text: '+ 11', value: 23 },
        { text: '+ 12', value: 24 },
      ],
    });
  },
  components: {
    ListSelector,
    MultiSelector,
    bool,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    set(keyName, value) {
      const toSend = {
        ...this.localValue,
        [keyName]: value,
      };
      this.$emit('update:modelValue', toSend);
    },
    reset() {
      const reset = {
        ...this.localValue,
        ...this.modelValue,
      };
      this.localValue = reset;
    }
  },
  mounted() {
    this.reset();
  },
};
</script>
