<template>
  <div>
    Exercice wording
    <div v-html="modelValue.wording">
    </div>
    <button @click="$emit('end')">close</button>
  </div>
</template>
<script>
export default {
  emits: ['end'],
  props: {
    modelValue: {},
  },
};
</script>
