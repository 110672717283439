<template>
  <main>
    <section>
      <GraphExercice
        :node_id="node_id"
        :showNode="showNode"
      ></GraphExercice>
      <Deconnect></Deconnect>
    </section>
  </main>
</template>

<script>
import GraphExercice from '@/components/GraphExercice/GraphExercice.vue';
import Deconnect from '@/components/Deconnect.vue';

export default {
  components: {
    Deconnect,
    GraphExercice,
    // GoogleAuth,
  },
  data() {
    return ({
      tmp: null,
    })
  },
  props: {
    node_id: {},
    showNode: {},
  },
  methods: {
  },
  async mounted() {
    // this.tmp = await schema.get('learning_node')
  },
}
</script>