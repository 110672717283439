import Soundfont from 'soundfont-player';
import { getAudioContext } from '@/core/audioContext';

export default async (
  instrumentName,
  options,
) => {
  const rt = await Soundfont.instrument(getAudioContext(), instrumentName, options);
  return (rt);
};
