import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '@/pages/Accueil.vue';
import Erd from '@/pages/Erd.vue';
import GraphExercice from '@/pages/GraphExercice.vue';
import Login from '@/pages/Login.vue';
import Crud from '@/pages/Crud.vue';
import CrudElement from '@/pages/CrudElement.vue';
import LoginCallback from '@/pages/LoginCallback.vue';
import Exercice from '@/pages/Exercice.vue';
import TestSynth from '@/pages/TestSynth.vue';
import TestClock from '@/pages/TestClock.vue';
import LearnGuitarNeck from '@/pages/LearnGuitarNeck.vue';
import TestScore from '@/pages/TestScore.vue';
import jwtDecode from 'jwt-decode';

const routes = [
    {
        path: '/',
        redirect: to => {
            return { name: 'Accueil' }
        },
    },
    {
        path: '/Graph',
        name: 'Accueil',
        component: Accueil,
    },
    {
        path: '/Graph/:node_id/:showNode',
        name: 'Description',
        component: Accueil,
        props: true,
    },
    {
        path: '/Graph/:node_id',
        name: 'AccueilNode',
        component: Accueil,
        props: true,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/erd',
        name: 'Erd',
        component: Erd,
    },
    // {
    //     path: '/GraphExercice',
    //     name: 'GraphExercice',
    //     component: GraphExercice,
    // },
    // {
    //     path: '/GraphExercice/:node_id',
    //     name: 'GraphExercice',
    //     component: GraphExercice,
    //     props: true,
    // },
    {
        path: '/TestSynth',
        name: 'TestSynth',
        component: TestSynth,
    },
    {
        path: '/TestScore',
        name: 'TestScore',
        component: TestScore,
    },
    {
        path: '/TestClock',
        name: 'TestClock',
        component: TestClock,
    },
    {
        path: '/Exercice/:exercice_id',
        name: 'Exercice',
        component: Exercice,
        props: true,
    },
    {
        path: '/crud',
        name: 'Crud',
        component: Crud
    },
    {
        path: '/crud/:tableName',
        name: 'CrudElement',
        component: CrudElement,
        props: true,
    },
    {
        path: '/login-callback',
        name: 'LoginCallback',
        component: LoginCallback
    },
    {
        path: '/LearnGuitarNeck',
        name: 'LearnGuitarNeck',
        component: LearnGuitarNeck
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

function storeRoute(route) {
    if (route.name === 'Login') {
        return ;
    }
    const toSet = {
        name: route.name,
        params: route.params,
        query: route.query,
    }
    localStorage.setItem('requestedRoute', JSON.stringify(toSet));
}

function restoreRoute() {
    const toSet = localStorage.getItem('requestedRoute');
    localStorage.removeItem('requestedRoute');
    if (toSet === null) {
        return null;
    }
    return (JSON.parse(toSet));
}

router.beforeEach((to, from, next) => {
    const unauthRoutes = [
        'Login',
        'LoginCallback',
    ];
    const token = localStorage.getItem('userToken');
    if (!token) {
        if (!unauthRoutes.includes(to.name)) {
            storeRoute(to);
            next({name: 'Login'});
        } else {
            next();
        }
        return ;
    }
    const currentDate = new Date().getTime() / 1000;
    const decodedToken = token ? jwtDecode(token) : null;
    let isAuthenticated = true;
    if (currentDate > decodedToken.exp) {
        localStorage.removeItem('userToken');
        isAuthenticated = false;
    }
    if (!isAuthenticated && !unauthRoutes.includes(to.name)) {
        storeRoute(to);
        next({name: 'Login'});
        return;
    }
    const restored = restoreRoute();
    if (isAuthenticated && unauthRoutes.includes(to.name)) {
        if (restored !== null) {
            next(restored);
        } else {
            next({name: 'Accueil'});
        }
        return;
    }
    if (restored !== null) {
        next(restored);
    } else {
        next();
    }
})


export default router
