<template>
  <div>
    Edition de l'exercice Simple Wording
    <EditHtml
      :model-value="localValue.wording"
      @update:modelValue="$emit('update:modelValue', {wording: $event})"
      :field="fields.wording"
    ></EditHtml>
  </div>
</template>

<script>
import EditHtml from '@/components/FormInput/text.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      fields: {
        wording: {
          is_updatable: true,
        },
      },
      localValue: {
        wording: '',
      }
    });
  },
  components: {
    EditHtml,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    reset() {
      if (typeof this.modelValue === 'object') {
        this.localValue = {
          ...this.localValue,
          ...this.modelValue,
        };
      }
    }
  },
  mounted() {
    this.reset();
  },
};
</script>
