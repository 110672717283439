<template>
  <div class="edit_float">
    <input
        type="number"
        :step="1 / Math.pow(10, precision)"
        :value="modelValue"
        @input="$emit('update:modelValue', prepareData($event.target.value))"
        :disabled="!field.is_updatable"
    >
    <div class="label">{{ field.column_name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    edit: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 2,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
  methods: {
    prepareData(numberInput) {
      if (numberInput === null || numberInput === undefined || numberInput.trim() === '') {
        return null;
      }
      return (parseFloat(numberInput));
    }
  }
}
</script>

<style lang="scss">
.edit_float {
  position: relative;
  div {
    display: block;
    position: absolute;
    top: -5px;
    left: 24px;
    font-size: 10px;
  }
}
</style>