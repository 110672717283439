<template>
  <g>
    <circle
        :filter="glow ? 'url(#glow)' : ''"
        class="learning_node"
        :cx="x"
        :cy="y"
        :r="r"
        stroke-width="5"
        @click="$emit('click', ev)"
    />
    <foreignObject :x="x - r" :y="y - r" :width="r * 2" :height="r * 2" class="learning_node_text">
      <div xmlns="http://www.w3.org/1999/xhtml">
        <slot></slot>
      </div>
    </foreignObject>
  </g>
</template>

<script>
import bool from '@/components/FormInput/bool.vue';

export default {
  emits: ['click'],
  props: {
    glow: {
      type: Boolean,
      default: false,
    },
    x: {},
    y: {},
    r: {
      type: Number,
      default: 100
    },
  },
};
</script>

<style lang="scss">
.learning_node {
  stroke: white;
  fill: #00000087;
  stroke-width: 3;
  cursor: pointer;
  transition: fill 0.1s ease;
  &:hover {
    fill: #ffffff2b;
  }
}

.learning_node_text {
  //fill: white;
  //font-size: 10;
  pointer-events: none;
  > div {
    color: white;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    padding: 10px;
    line-height: 1.5;
  }
}
</style>