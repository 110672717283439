import axiosInstance from '@/core/axiosInstance';
import Parameters from '@/core/Parameters';

class Schema {
    constructor() {
        this.loaded = {};
    }
    async get(tableName) {
        if (this.loaded[tableName] === undefined) {
            const toSet = (await axiosInstance.get(`${Parameters.SCHEMA_URI}${tableName}`)).data;
            if (toSet.length)
                this.loaded[tableName] = toSet;
        }
        return (this.loaded[tableName]);
    }
}

export default new Schema();