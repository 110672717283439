<template>
  <div>
    <h1>Configuration de l'exercice des inteval par demi tons</h1>
    <label>Nom affiché</label>
    <ListSelector
        :list="listLabels"
        @update:modelValue="set('showLabel', $event)"
        :model-value="localValue.showLabel"
    >
    </ListSelector>
    <label>Nom demandé</label>
    <ListSelector
        :list="listLabels"
        @update:modelValue="set('requestedLabel', $event)"
        :model-value="localValue.requestedLabel"
    >
    </ListSelector>
    <IntervalSelector
        v-model="localValue.intervalSelection"
        @update:modelValue="set('intervalSelection', $event)"
    />
  </div>
</template>

<script>
// import MultiSelector from '@/components/SpecificFormInput/MultiSelector.vue';
import ListSelector from '@/components/SpecificFormInput/ListSelector.vue';
import IntervalSelector from '@/MusicalProcessing/Components/IntervalSelector.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      localValue: {
        showLabel: false,
        requestedLabel: false,
        intervalSelection: [],
      },
      listLabels: [
        { text: 'Valeur', value: 'value' },
        { text: 'Nom', value: 'name' },
        { text: 'Nom court', value: 'shortname' },
        { text: 'Valeur / 2', value: 'text' },
      ],
    });
  },
  components: {
    IntervalSelector,
    ListSelector,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    set(keyName, value) {
      const toSend = {
        ...this.localValue,
        [keyName]: value,
      };
      this.$emit('update:modelValue', toSend);
    },
    reset() {
      const reset = {
        ...this.localValue,
        ...this.modelValue,
      };
      this.localValue = reset;
    }
  },
  mounted() {
    this.reset();
  },
};
</script>
