import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Parameters from '@/core/Parameters';

const instance = axios.create({
    baseURL: Parameters.SERVER_URL,
    // headers: {'X-Custom-Header': 'foobar'}
});

// Ajoutez un interceptor pour les requêtes
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('userToken');


    // Si le token est présent dans le localStorage, ajoutez-le au header d'authentification
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// Ajoutez un interceptor pour les réponses
instance.interceptors.response.use(response => {
    return response;
}, error => {
    // Si le token est expiré ou s'il y a une autre erreur d'authentification
    if (error.response.status === 401) {
        // Redirigez vers la page d'authentification
        router.push('/login');
    }

    return Promise.reject(error);
});

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('userToken');

    if (token) {
        const decodedToken = jwtDecode(token);
        const currentDate = new Date().getTime() / 1000;  // getTime renvoie en millisecondes, mais la date d'expiration JWT est en secondes

        if (currentDate > decodedToken.exp) {
            // Si le token a expiré, redirigez vers la page de connexion et arrêtez la requête
            router.push('/login');
            localStorage.removeItem('userToken');
            return Promise.reject('Token expired');
        }

        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

export default instance;