<template>
  <div
    class="dialog"
    :class="{active: value === true}"
  >
    <template v-if="value === true">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';

export default {
  data() {
    return ({
      data: null,
    })
  },
  props: {
    value: {},
  },
  computed: {
    getValue() {
      return (this.value);
    }
  },
  watch: {
    getValue() {
      this.checkScroll();
    },
  },
  methods: {
    checkScroll() {
      if (this.value !== null && this.value !== undefined) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
  },
  async mounted() {
    this.checkScroll();
  },
  beforeUnmount() {
    document.body.classList.remove('no-scroll');
  },
}
</script>

<style lang="scss">
.dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display:flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  pointer-events: none;

  .overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    pointer-events: none;
    //backdrop-filter: blur(3px);
    //background-color: #57525270;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &.active {
    background-color: rgb(30 28 28 / 90%);
    z-index:2;
    pointer-events: auto;
    .overlay {
      opacity: 1;
    }
  }

}
</style>