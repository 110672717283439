import { getAudioContext } from '@/core/audioContext';
import Notes from '@/MusicalProcessing/Factories/Notes';

export default {
    playNoteName(noteStr) {
        const noteToPlay = Notes.find((note) => {
            return (
                `${note.nameUp}${note.octave}` === noteStr
                || `${note.nameDown}${note.octave}` === noteStr
            )
        });
        if (!noteToPlay) {
            throw 'cannot find note';
        }
        this.playNote(noteToPlay);
    },
    playNote(note) {
        this.playFrequency(note.frequency);
    },
    playFrequency(baseFrequency) {
        var audioCtx = getAudioContext();

        // Créer un oscillateur
        var oscillator = audioCtx.createOscillator();
        oscillator.type = 'sawtooth';
        oscillator.frequency.setValueAtTime(baseFrequency, audioCtx.currentTime); // La fréquence en Hz

        // Créer un filtre passe-bas
        var lowPassFilter = audioCtx.createBiquadFilter();
        lowPassFilter.type = 'lowpass';
        lowPassFilter.frequency.setValueAtTime(baseFrequency, audioCtx.currentTime); // Fréquence de coupure initiale en Hz
        lowPassFilter.Q.setValueAtTime(2, audioCtx.currentTime); // Un Q élevé pour une pente plus abrupte

        // Paramètres de l'enveloppe ADSR pour le filtre
        var attackTime = 0.01;
        var decayTime = 0.5;
        var sustainTime = 0.2;
        // var sustainFrequency = 4000; // Fréquence de coupure de soutien
        var sustainFrequency = baseFrequency * 0.5; // Fréquence de coupure de soutien
        var releaseTime = 1.5;
        // var maxFrequency = 15000; // La fréquence maximale atteinte pendant l'attaque
        var maxFrequency = baseFrequency * 1.2; // La fréquence maximale atteinte pendant l'attaque

        // Appliquer l'enveloppe ADSR à la fréquence de coupure du filtre
        // Attack
        lowPassFilter.frequency.linearRampToValueAtTime(maxFrequency, audioCtx.currentTime + attackTime);
        // Decay
        lowPassFilter.frequency.linearRampToValueAtTime(sustainFrequency, audioCtx.currentTime + attackTime + decayTime);
        lowPassFilter.frequency.linearRampToValueAtTime(sustainFrequency, audioCtx.currentTime + attackTime + decayTime + sustainTime);
        lowPassFilter.frequency.linearRampToValueAtTime(0, audioCtx.currentTime + attackTime + decayTime + sustainTime + releaseTime);

        oscillator.connect(lowPassFilter);
        var gainNode = audioCtx.createGain();
        gainNode.gain.setValueAtTime(0.5, audioCtx.currentTime);

        // var playTime = attackTime + decayTime + sustainTime + releaseTime; // en secondes
        var fadeTime = 0.1; // en secondes
        // var playTime = 0.5; // en secondes
        var playTime = attackTime + decayTime + sustainTime + releaseTime - fadeTime;

        gainNode.gain.setValueAtTime(0.5, audioCtx.currentTime + playTime);
        gainNode.gain.linearRampToValueAtTime(0, audioCtx.currentTime + playTime + fadeTime);


        lowPassFilter.connect(gainNode);
        gainNode.connect(audioCtx.destination);

        oscillator.start(audioCtx.currentTime);

        // Simuler la fin de la note pour appliquer le release
        var noteEndTime = audioCtx.currentTime + attackTime + decayTime + sustainTime; // +1 seconde de soutien
        oscillator.stop(noteEndTime + releaseTime); // Assurez-vous d'arrêter l'oscillateur après le release

        // Release
        lowPassFilter.frequency.setValueAtTime(sustainFrequency, noteEndTime);
        lowPassFilter.frequency.linearRampToValueAtTime(500, noteEndTime + releaseTime); // Retour à la fréquence de coupure initiale

    }
}