<template>
  <div class="note_asc_desc_show" v-if="performanceEvaluator !== null">
    <div class="line score">
      <div>
        <span v-if="performanceEvaluator.score.count > 0">
          {{ Math.round(10000 * performanceEvaluator.getGlobalScore()) / 100 }} %
          <span class="small_score">
            ({{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %)
          </span>
        </span>
      </div>
      <div>
        {{ getNoteName }}
        {{ performanceEvaluator.getActualQuestion()?.semitone >= 12 ? '+' : '-' }}
        {{ getIntervalName }}
      </div>
    </div>
    <MusicClock
        v-if="modelValue.clock_mode"
        @note="check($event.midiNote)"
        :errors="performanceEvaluator.errorList.map((_) => _.note)"
        :score="getScore"
        :show-notes-name="modelValue.show_notes"
        :note-label="modelValue.circleLabel"
    >
      <div class="note_show">
        {{ getNoteName }}
        {{performanceEvaluator.getActualQuestion()?.semitone >= 12 ? '+' : '-'}}
        {{ getIntervalName }}
      </div>
    </MusicClock>
    <div v-for="_ in notePresentation" :key="_" class="line" v-else>
      <div>
        <button
            :class="{error: isNoteError(_)}"
            @click="check(_)"
        >
          {{ getNote(_)[modelValue.circleLabel] }}
        </button>
      </div>
    </div>
    <div class="line score">
      <div>
<!--        <span v-if="performanceEvaluator.score.count > 0">-->
<!--          {{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %-->
<!--        </span>-->
      </div>
<!--      <div>-->
<!--        {{ getNote(performanceEvaluator.getActualQuestion()?.note).nameUpFr }}-->
<!--        <span-->
<!--            v-if="performanceEvaluator.getActualQuestion()?.semitone >= 12"-->
<!--        >-->
<!--          +-->
<!--        </span>{{ performanceEvaluator.getActualQuestion()?.semitone - 12 }}-->
<!--        / 2-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import Notes from '@/MusicalProcessing/Factories/Notes';
import Interval from '@/MusicalProcessing/Factories/Interval';
import SimpleSynth from '@/core/simpleSynth';
import PerformanceEvaluator from '@/core/PerformanceEvaluator';
import {toRaw} from 'vue';
import MusicClock from '@/components/MusicClock.vue';

export default {
  components: {MusicClock},
  props: {
    modelValue: {},
    statistiques: {},
  },
  data() {
    return ({
      pointPotential: null,
      response: '',
      exerciceConfig: {
        note: [],
        semitone: [],
      },
      notePresentation: [],
      intervalList: Interval,
      currentInterval: null,
      noteIntervalDuration: 500,
      playNote: null,
      playNoteInterval: null,
      minimumScore: 0.6,
      actualTraining: [],
      indexNextQuestion: null,
      lastTimeKeyed: null,
      baseTimeEqualOne: 125, // 1 seconde donne un point,
      saveIntervalTime: 1000, // 10 seconde
      saveIntervalInstance: null,
      performanceEvaluator: null,
    })
  },
  computed: {
    getNoteName() {
      const label = this.modelValue.centerLabel || 'nameUp';
      const note = this.getNote(this.performanceEvaluator.getActualQuestion()?.note)[label]
       return (note);
    },
    getIntervalName() {
      const semitone = this.performanceEvaluator.getActualQuestion().semitone - 12;
      const toGet = semitone > 0 ? semitone : -semitone;
      return (this.intervalList[toGet]?.shortname);
    },
  },
  methods: {
    getNote(noteIndex) {
      return (Notes[noteIndex]);
    },
    check(note) {
      const response = {
        note,
        semitone: this.performanceEvaluator.getActualQuestion().semitone,
      }
      this.performanceEvaluator.doResponse(response);
      const toSave = this.performanceEvaluator.getToSave();
      this.$emit('update:statistiques', toSave);
      this.test();
    },
    test() {
      // setTimeout((_) => {
      //   const toSet = {
      //     note:this.performanceEvaluator.getActualQuestion().note,
      //     semitone:this.performanceEvaluator.getActualQuestion().semitone,
      //   };
      //   this.check(toSet.note + toSet.semitone);
      // }, 300);
    },
    play() {
      const actualQuestion = this.performanceEvaluator.getActualQuestion();
      const currentNote = Notes[actualQuestion.note];
      const currentNoteInterval = Notes[actualQuestion.note + actualQuestion.interval];
      const playNote = `${currentNote.nameDown}${currentNote.octave - 2}`;
      const playNoteInterval = `${currentNoteInterval.nameDown}${currentNoteInterval.octave - 2}`;
      SimpleSynth.playNoteName(playNote);
      window.setTimeout(() => {
        SimpleSynth.playNoteName(playNoteInterval);
      }, this.noteIntervalDuration);
    },
    isNoteError(note) {
      return (this.performanceEvaluator.errorList.some((_) => _.note === note));
    },
    isIntervalDisabled(interval) {
      return (!this.performanceEvaluator.getEnabledCombinaisons().some((_) => _.interval === interval));
    },
  },
  beforeUnmount() {
    window.clearInterval(this.saveIntervalInstance);
  },
  async mounted() {
    this.notePresentation = Notes
        .filter((_) => {
          return (
              _.octave === 4
              &&
              (!(_.altered) || this.modelValue.enable_altered)
          );
        })
        .map((_) => _.midiNote)
    ;
    this.exerciceConfig.note = [
      5, 0, 7, 2, 9, 4, 11, 6, 1, 8, 3, 10
    ].map((_) => _ + 72);
    this.exerciceConfig.semitone = this.modelValue.semitone;
    this.performanceEvaluator = new PerformanceEvaluator(this.exerciceConfig, this.statistiques);
    this.performanceEvaluator.setCheckMethod((response, actual) => {
      return ((actual.note + actual.semitone - 12) % 12 === (response.note) % 12);
    })
    this.performanceEvaluator.setNew();
    this.performanceEvaluator.setNewOnError = false;
    this.test();
    setInterval(() => {
      const tmp = this.performanceEvaluator.getPointPotential();
      const actual = this.performanceEvaluator.getActualQuestion();
      const needed = actual.count - actual.point;
      const pointPotential = tmp > needed ? needed : tmp;
      this.pointPotential = pointPotential;
    }, 100);
  }
};
</script>

<style lang="scss">
.note_asc_desc_show {
  display:flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  height: 100vh - env(safe-area-inset-bottom);
  > div.line {
    font-weight: bold;
    &.score {
      background-color: black;
      color: white;
    }
    display:flex;
    width: 100%;
    justify-content: stretch;
    flex: 1;
    > div {
      flex: 1;
      font-size: 22px;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &.error {
          background-color: #ff000047;
        }
        border-radius: 0;
      }
      align-items: center;
      justify-content: center;
      display:flex;
    }
  }
  .score {
    font-size: 24px;
    text-align: center;
    .small_score {
      font-size:0.7em;
      font-style: italic;
      font-weight: normal;
    }
  }
}
</style>