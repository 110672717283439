import ExerciceType from '@/core/ExerciceType';
import NoteAscDescShow from '@/ExerciceType/NoteAscDesc/Show.vue';
import NoteAscDescEdit from '@/ExerciceType/NoteAscDesc/Edit.vue';
import SimpleWordingShow from '@/ExerciceType/SimpleWording/Show.vue';
import SimpleWordingEdit from '@/ExerciceType/SimpleWording/Edit.vue';
import LearnGuitarNeckShow from '@/ExerciceType/LearnGuitarNeck/Show.vue';
import LearnGuitarNeckEdit from '@/ExerciceType/LearnGuitarNeck/Edit.vue';
import EarTrainingShow from '@/ExerciceType/EarTraining/Show.vue';
import EarTrainingEdit from '@/ExerciceType/EarTraining/Edit.vue';
// import IntervalShow from '@/ExerciceType/Interval/Show.vue';
// import IntervalEdit from '@/ExerciceType/Interval/Edit.vue';
import IntervalSemiTonsShow from '@/ExerciceType/IntervalSemiTons/Show.vue';
import IntervalSemiTonsEdit from '@/ExerciceType/IntervalSemiTons/Edit.vue';
import MusicClockLearnShow from '@/ExerciceType/MusicClockLearn/Show.vue';
import MusicClockLearnEdit from '@/ExerciceType/MusicClockLearn/Edit.vue';

export default [
    new ExerciceType(
        'Note Ascendante / Descendante',
        NoteAscDescShow,
        NoteAscDescEdit,
    ),
    new ExerciceType(
        'Simple Wording',
        SimpleWordingShow,
        SimpleWordingEdit,
    ),
    new ExerciceType(
        'Learn guitar neck',
        LearnGuitarNeckShow,
        LearnGuitarNeckEdit,
    ),
    new ExerciceType(
        'Ear Training',
        EarTrainingShow,
        EarTrainingEdit,
    ),
    // new ExerciceType(
    //     'Interval',
    //     IntervalShow,
    //     IntervalEdit,
    // ),
    new ExerciceType(
        'IntervalSemiTone',
        IntervalSemiTonsShow,
        IntervalSemiTonsEdit,
    ),
    new ExerciceType(
        'MusicClockLearn',
        MusicClockLearnShow,
        MusicClockLearnEdit,
    ),
]