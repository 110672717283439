<template>
  <div>
<!--    {{ getFkRelations }}-->
    <svg width="3000" height="2000" xmlns="http://www.w3.org/2000/svg">
      <!-- Dessiner une table comme un rectangle -->
      <template
          v-for="table in getTablesConfigs"
          :key="`table-${table.table_schema}-${table.table_name}`"
      >
<!--        Rectangle complet-->
        <rect
            :x="table.position_x"
            :y="table.position_y"
            rx="5"
            ry="5"
            :width="config.tableWidth"
            :height="table.height"
            style="fill:blue;stroke:black;stroke-width:2;"
        />
<!--        Rectangle blanc-->
        <rect
            :x="table.position_x"
            :y="table.position_y + config.tableHeadHeight"
            :width="config.tableWidth"
            :height="table.heightCols"
            style="fill:white;stroke:black;stroke-width:2;"
        />
        <!-- Texte pour le nom de la table -->
        <text
            :x="table.position_x + (config.tableWidth / 2)"
            :y="table.position_y + (config.tableHeadHeight / 2)"
            :font-family="config.fontFamily"
            font-size="24"
            fill="white"
            text-anchor="middle"
            dy=".3em"
            font-weight="bold"
        >
          {{table.table_name}}
        </text>
        <!-- Texte pour le nom de la table -->
        <text
            :key="column.column_name"
            v-for="(column, index) in table.columns"
            :x="table.position_x + 5"
            :y="
                table.position_y
                + config.tableHeadHeight
                + (index * config.tableColumnsHeight)
                + (config.tableColumnsHeight / 3)
                + 5
            "
            :font-family="config.fontFamily"
            font-size="24"
            fill="black"
            font-weight="bold"
        >
            {{column.column_name}}
        </text>
<!--        Texte des colonnes-->
        <text
            :key="column.column_name"
            v-for="(column, index) in table.columns"
            :x="table.position_x + 5"
            :y="
              table.position_y
              + config.tableHeadHeight
              + (index * config.tableColumnsHeight)
              + (2 * config.tableColumnsHeight / 3)
          "
            :font-family="config.fontFamily"
            font-size="12"
            fill="black"
            font-style="italic"

        >
            {{column.data_type}}
        </text>
      </template>
      <line
          v-for="fk in getFkRelations"
          :key="fk.constraint_name"
          :x1="fk.xStart + 10"
          :y1="fk.yStart"
          :x2="fk.xEnd - 10"
          :y2="fk.yEnd"
          stroke="white>>"
      />

    </svg>
  </div>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';
export default {
  data() {
    return ({
      dataList: [],
      uri: '/editor/get_erd',
      config: {
        fontFamily: 'sans-serif',
        tableWidth: 450,
        tableHeadHeight: 50,
        tableBottomHeight: 20,
        tableColumnsHeight: 50,
      }
    });
  },
  props: {
    // uri: {},
  },
  computed: {
    getHeaders() {
      if (this.dataList.length) {
        return (Object.keys(this.dataList[0]))
      }
      return ([]);
    },
    getTablesConfigs() {
      // const tablePosition = {};
      const rt = this.dataList.map((_) => {
        const rt = {
          ..._,
          height: this.config.tableHeadHeight
              + this.config.tableBottomHeight
              + (this.config.tableColumnsHeight * _.columns.length),
          heightCols: (this.config.tableColumnsHeight * _.columns.length),
          fullName: `${_.table_schema}.${_.table_name}`,
          columns: _.columns.map((column, index) => {
            return ({
              ...column,
              y:  _.position_y
                  + this.config.tableHeadHeight
                  + (index * this.config.tableColumnsHeight)
                  + (this.config.tableColumnsHeight / 2)
            });
          })
        };
        return ([rt.fullName, rt]);
      });
      return (Object.fromEntries(rt));
    },
    getFkRelations() {
      const config = this.getTablesConfigs;
      return (Object.values(config).reduce((acc, elm) => {
        elm.columns.forEach((_) => {
          if (_.fk_from !== null) {
            _.fk_from.forEach((fk) => {
              let xStart = config[elm.fullName].position_x;
              let xEnd = config[fk.foreign_table].position_x;
              if (xStart <= xEnd) {
                xStart += this.config.tableWidth;
              } else {
                xEnd += this.config.tableWidth;
              }
              acc.push({
                from: elm.fullName,
                to: fk.foreign_table,
                constraint_name: fk.constraint_name,
                column_name: _.column_name,
                xStart,
                xEnd,
                yStart: _.y,
                yEnd: config[fk.foreign_table].position_y
                  + (this.config.tableHeadHeight / 2),
              });
            })
          }
        })
        // acc.push(elm);
        return (acc);
      }, []));
    }
  },
  async mounted() {
    const {data} = await axiosInstance.get(this.uri);
    this.dataList = data;
  }
}
</script>