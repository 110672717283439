<template>
  <div>
    <LearnGuitarNeckInterface
      :setting="modelValue"
    ></LearnGuitarNeckInterface>
  </div>
</template>
<script>
import LearnGuitarNeckInterface from '@/Exercices/GuitarNeck/Notes/UserInterface.vue';

export default {
  components: {LearnGuitarNeckInterface},
  props: {
    modelValue: {},
  },
};
</script>
