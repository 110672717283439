import Interval from '../Models/Interval';

export default {
  0: new Interval(0, 'Unisson', 'Uni'),
  1: new Interval(1, 'Seconde mineure', '2m'),
  2: new Interval(2, 'Seconde majeure', '2M'),
  3: new Interval(3, 'Tierce mineure', '3m'),
  4: new Interval(4, 'Tierce Majeure', '3M'),
  5: new Interval(5, 'Quarte', '4'),
  6: new Interval(6, 'Quinte diminuée', '5d'),
  7: new Interval(7, 'Quinte juste', '5'),
  8: new Interval(8, 'Sixte mineure', '6m'),
  9: new Interval(9, 'Sixte majeure', '6M'),
  10: new Interval(10, 'Septième mineure', '7m'),
  11: new Interval(11, 'Septième majeure', '7M'),
  12: new Interval(12, 'Octave', 'Oct'),
};
