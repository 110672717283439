import { createApp } from 'vue'
import App from './App.vue'
import router from '@/core/router';
import "@/styles/_mixins.scss";
import "@/styles/_global.scss";
import CKEditor from '@ckeditor/ckeditor5-vue';

window.router = router;

const app = createApp(App)
    .use( CKEditor )
    .use(router)


app.mount('#app');