// const audioContext = new AudioContext();
//
// export default audioContext;
let audioContext;

function getAudioContext() {
    if (!audioContext) {
        audioContext = new AudioContext();
    }
    return audioContext;
}

export { getAudioContext };