<template>
  <div class="edit_varchar">
    <input
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :disabled="!field.is_updatable"
    >
    <div class="label">{{ field.column_name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    edit: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
}
</script>

<style lang="scss">
.edit_varchar {
  position: relative;
  div {
    display: block;
    position: absolute;
    top: -5px;
    left: 24px;
    font-size: 10px;
  }
}
</style>