<template>
  <div>
    <button @click="test">TestSynth</button>
  </div>
</template>

<script>
import simpleSynth from '@/core/simpleSynth';
import Notes from '@/MusicalProcessing/Factories/Notes';
export default {
  methods: {
    test() {
      const noteStr = 'A3';
      // const noteToPlay = Notes.find((note) => {
      //   return (
      //       `${note.nameUp}${note.octave}` === noteStr
      //       || `${note.nameDown}${note.octave}` === noteStr
      //   )
      // })

      // console.log(Notes[69]);
      simpleSynth.playNoteName(noteStr);
      // simpleSynth.playNote(noteToPlay);
      // simpleSynth.playFrequency(440);
    },
  },
  mounted() {

  },
};
</script>
