<template>
  <div class="learn_guitar_neck">
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
        </v-toolbar-items>
      </v-toolbar>
      <LearnGuitarNeckSetting
        v-model="setting"
      ></LearnGuitarNeckSetting>
    </v-card>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <LearnGuitarNeckInterface
        v-if="dialog"
        :setting="setting"
      ></LearnGuitarNeckInterface>
    </v-dialog>
    <v-bottom-navigation
      color="primary"
      fixed
    >
      <v-btn @click="dialog = !dialog">
        <v-icon v-if="dialog">mdi-play-pause</v-icon>
        <v-icon v-else>mdi-play-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import LearnGuitarNeckInterface from '@/Exercices/GuitarNeck/Notes/UserInterface.vue';
// import Config from '@/Exercices/ReadingScore/Setting.vue';
import LearnGuitarNeckSetting from '@/Exercices/GuitarNeck/Notes/Setting.vue';

export default {
  data() {
    return ({
      dialog: false,
      setting: {},
    });
  },
  components: {
    // Config,
    LearnGuitarNeckInterface,
    LearnGuitarNeckSetting,
  },
};
</script>

<style lang="scss">
.learn_guitar_neck {
  .v-card {
    height: 100%;
  }
}
</style>
