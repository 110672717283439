<template>
  <div class="graph_exercice" ref="graph_exercice">
    <Background :currentIndex="node_id"/>
    <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" class="graph_svg">
      <defs>
        <filter id="glow">
          <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <circle
          class="big_circle"
          v-for="bigCircle in bigCircles"
          :cx="width / 2"
          :cy="height / 2"
          :r="bigCircle * space"
          :key="bigCircle"
      />
      <line
          v-for="l in getRelations"
          :key="l.id"
          :x1="l.x1"
          :y1="l.y1"
          :x2="l.x2"
          :y2="l.y2"
          stroke="#ffffff80"
          stroke-width="3"
      ></line>
      <LearningNode
        :class="{
          isOn: learningNode.id == node_id,
          // disabled: enabled.includes(learningNode.id) !== true
        }"
        :glow="learningNode.id == node_id"
        v-for="learningNode in getPreparedLearningNodeList"
        :key="`learning_node${learningNode.id}`"
        :x="learningNode.position_x"
        :y="learningNode.position_y"
        :r="circleRadius"
        @click="setSelectedLearningNode(learningNode.id)"
      >
        {{ learningNode.name }}
      </LearningNode>
    </svg>
    <Dialog
      :value="node_id !== undefined && showNode !== undefined"
    >
      <LearningNodeDetails
        :modelValue="node_id"
      ></LearningNodeDetails>
    </Dialog>
  </div>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';
import LearningNode from '@/components/GraphExercice/LearningNode.vue';
import LearningNodeDetails from '@/components/GraphExercice/LearningNodeDetails.vue';
import Dialog from '@/components/Dialog.vue';
import Background from '@/components/Background.vue';

export default {
  components: {
    LearningNode,
    LearningNodeDetails,
    Dialog,
    Background,
  },
  data() {
    return ({
      enabled: [7, 13, 17],
      tmp: {},
      learningNodeList: [],
      learningNodeDependance: [],
      uri: '/CRUD/learning_node',
      width: 2200,
      height: 2200,
      space: 200,
      circleRadius: 50,
      bigCircles: [
          1.5,
          3.5,
          2.5,
          4.5,
          5.5,
          6.5
      ]
    });
  },
  props: {
    node_id: {},
    showNode: {},
  },
  computed: {
    getHeaders() {
      if (this.learningNodeList.length) {
        return (Object.keys(this.learningNodeList[0]))
      }
      return ([]);
    },
    getPreparedLearningNodeList() {
      return (this.learningNodeList.map((_) => {
        if (_.position_x !== null && _.position_y !== null) {
          return (_);
        }

        return ({
          ..._,
          position_x: (this.space * _.distance * Math.cos(_.angle * Math.PI / 180)) + (this.width / 2),
          position_y: (this.space * _.distance * Math.sin(_.angle * Math.PI / -180)) + (this.height / 2),
        })
      }))
    },
    getNodesPositions() {
        return (
            this.getPreparedLearningNodeList.reduce((acc, elm) => {
              acc[elm.id] = {
                position_x: elm.position_x,
                position_y: elm.position_y,
              }
              return (acc);
            }, {})
        );
    },
    getRelations() {
      if (this.learningNodeDependance === null) {
        return ([]);
      }

      const nodePos = this.getNodesPositions;
      return (
        this.learningNodeDependance.map(_ => {
          const from = nodePos[_.learning_node_to_unlock_id];
          const to = nodePos[_.learning_node_depend_on_id];
          const x = to.position_x - from.position_x;
          const y = to.position_y - from.position_y;
          const h = Math.sqrt(x * x + y * y);
          const ratio = this.circleRadius / h;
          // const angle = Math.atan2(y, x);
          return ({
            ..._,
            x1: from.position_x + (ratio * x),
            y1: from.position_y + (ratio * y),
            x2: to.position_x - (ratio * x),
            y2: to.position_y - (ratio * y),
          });
        })
      );
    },
  },
  methods: {
    smoothScroll(top, left) {
      let startTime = null;
      const duration = 1000;
      const element = this.$refs.graph_exercice;
      function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        element.scrollTop = (top - element.scrollTop) * progress + element.scrollTop;
        element.scrollLeft = (left - element.scrollLeft) * progress + element.scrollLeft;

        if (timeElapsed < duration) {
          window.requestAnimationFrame(animation);
        }
      }

      window.requestAnimationFrame(animation);
    },
    setSelectedLearningNode(learningNodeId) {
      // if (this.enabled.includes(learningNodeId) !== true) {
      //   return;
      // }
      if (parseInt(this.node_id) === parseInt(learningNodeId)) {
        this.$router.push({
          name: 'Description',
          params: {
            node_id: learningNodeId,
            showNode: 'show',
          },
        });
      } else {
        this.$router.push({
          name: 'AccueilNode',
          params: {
            node_id: learningNodeId,
          },
        });
        this.moveTo(learningNodeId);
      }
    },
    moveTo(nodeToSetInput) {
      const element = this.$refs.graph_exercice;
      const child = element.children[0];

      const fullWidth = child.clientWidth;
      const fullHeight = child.clientHeight;
      const width = element.clientWidth;
      const height = element.clientHeight;
      let setX = fullWidth / 2;
      let setY = fullHeight / 2;
      const nodeToSet = nodeToSetInput;
      let setPosition = this.getPreparedLearningNodeList.find((_) => _.id === parseInt(nodeToSet, 10));
      setX = setPosition.position_x;
      setY = setPosition.position_y;
      const setLeft = setX - (width / 2);
      const setTop = setY - (height / 2);
      this.smoothScroll(setTop, setLeft)
    },
    setPosition(nodeToSetInput) {
      const element = this.$refs.graph_exercice;
      const child = element.children[0];

      const fullWidth = child.clientWidth;
      const fullHeight = child.clientHeight;
      const width = element.clientWidth;
      const height = element.clientHeight;
      let setX = fullWidth / 2;
      let setY = fullHeight / 2;
      let nodeToSet = this.node_id;
      if (nodeToSetInput) {
        nodeToSet = nodeToSetInput;
      }
      if (nodeToSet) {
        let setPosition = this.getPreparedLearningNodeList.find((_) => _.id === parseInt(nodeToSet, 10));
        setX = setPosition.position_x;
        setY = setPosition.position_y;
      }
      const setLeft = setX - (width / 2);
      const setTop = setY - (height / 2);
      element.scrollLeft = setLeft;
      element.scrollTop = setTop;
    },
  },
  async mounted() {
    if (!this.node_id) {
      this.$router.replace({
        name: 'AccueilNode',
        params: {
          node_id: 17,
        },
      });
    }
    const tmp = (await axiosInstance.get('graph_exercice')).data[0];
    this.learningNodeList = tmp.learning_nodes;
    this.learningNodeDependance = tmp.learning_node_unlocking_dependance;
    this.setPosition();
  }
}
</script>

<style lang="scss">
.graph_exercice {
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  svg.graph_svg {
    transition: zoom 1s ease;
    .isOn > .learning_node {
      fill: #a584cc66;
    }
    .disabled {
      > .learning_node_text > div {
        color: #00000099;
      }
      > .learning_node {
        stroke: #ffffff0f;
        fill: #ffffff0f;
        cursor: initial;
        color: black;
      }
    }
    .big_circle {
      //stroke: #00000080;
      stroke: #ffffff42;
      stroke-width: 1;
      fill: #00000035;
    }
  }
  .tmp_ok {
    stroke: #5ee05e;
    fill: #5ee05e1f;
  }
}
</style>