<template>
  <div class="exercice"
     v-if="getExerciceType !== null && dataLoaded"
  >
    <ComponentProxy
        v-model="exercice.setting"
        :component="getExerciceType"
        @end="$emit('end', $event)"
        @update:statistiques="setStat"
        :statistiques="oldStat"
    >
    </ComponentProxy>
  </div>
</template>

<script>
import ExerciceList from '@/ExerciceType/index';
import axiosInstance from '@/core/axiosInstance';
import ComponentProxy from '@/components/ComponentProxy.vue';

export default {
  components: {ComponentProxy},
  data() {
    return ({
      exercice: null,
      oldStat: null,
      dataLoaded: false,
    });
  },
  props: {
    exercice_id: {},
  },
  methods: {
    setStat(stat) {
      axiosInstance.post(`exercice_performed/${this.exercice_id}`, stat);
    },
  },
  computed: {
    getExerciceType() {
      const exerciceType = this.exercice?.exercice_type;
      if (exerciceType === undefined) {
        return null;
      }

      const exercice = ExerciceList.find((_) => _.name === exerciceType);
      if (exercice === undefined) {
        throw new Error('impossible de trouver ce type d\'exercice');
      }
      return (exercice.showExercice);
    },
  },
  async mounted() {
    const exercice = (await axiosInstance.get(`exercice/${this.exercice_id}`)).data[0];
    this.exercice = exercice;
    const currentStat = (await axiosInstance.get(`exercice_performed/${this.exercice_id}`)).data[0];
    if (currentStat !== undefined) {
      this.oldStat = currentStat.stat;
    }
    this.dataLoaded = true;
  }
};
</script>

<style lang="scss">
.exercice {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  //position: fixed;
  //background-color:#544c47;
  > div {
    height: 100%;
    width: 100%;
  }
}
</style>