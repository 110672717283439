<template>
  <div class="google_connect">
    <a :href="`${SERVER_URL}/auth/google`">Se connecter avec Google</a>
  </div>
</template>

<script>
import Parameters from '@/core/Parameters';

export default {
  data() {
    return ({
      SERVER_URL: Parameters.SERVER_URL
    });
  },
};
</script>

<style>
.google_connect {
  //position:fixed;
  //top: 0;
  //left: 0;
}
</style>