<template>
  <main>
    <section class="container">
      <h1>Page de login</h1>
      Vous etes maintenant authentifié
    </section>
  </main>
</template>

<script>

export default {
  components: {
  },
  data() {
    return ({
    })
  },
  methods: {
  },
  mounted() {
    const token = this.$route.query.token;
    if (token) {
      // Stockez le token où vous le souhaitez (par exemple, dans Vuex ou localStorage)
      localStorage.setItem('userToken', token);

      // Redirigez l'utilisateur vers la page d'accueil ou toute autre page de votre choix
      this.$router.push({ name: 'Accueil' });
    } else {
      // Gérez l'erreur si le token n'est pas dans l'URL
      console.error("Token not found!");
    }
  }
}
</script>