<template>
  <MultiSelector
      :list="getList"
      v-model="getModelValue"
  >
  </MultiSelector>
</template>

<script>
import list from '@/MusicalProcessing/Factories/Notes';
import MultiSelector from '@/components/SpecificFormInput/MultiSelector.vue';

export default {
  components: {MultiSelector},
  data() {
    return ({
      selectedIntervals: [],
    });
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return ([]);
      }
    },
  },
  computed: {
    getModelValue: {
      get() {
        return (this.modelValue);
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    getList() {
      const rt = Object.values(list).map((_) => ({
        text: `${_.nameUp} ${_.octave}`,
        value: _.midiNote,
      }));
      return (rt);
    },
  },
};
</script>