<template>
  <div>
    <h1>Test Score</h1>
    <VexFlow/>
  </div>
</template>

<script>
import VexFlow from '@/components/VexFlow.vue';
export default {
  components: {
    VexFlow,
  },
};
</script>