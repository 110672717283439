<template>
  <div class="note_asc_desc_show" v-if="performanceEvaluator !== null">
    <div class="line score">
      <div>
        <span v-if="performanceEvaluator.score.count > 0">
          {{ Math.round(10000 * performanceEvaluator.getGlobalScore()) / 100 }} %
          <span class="small_score">
            ({{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %)
          </span>
        </span>
      </div>
      <div>
        <button @click="play">Replay</button>
      </div>
    </div>
    <div class="line">
      <div>
        <button
            :class="{error: isIntervalError(1)}"
            @click="check(1)"
            :disabled="isIntervalDisabled(1)">seconde mineure</button>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(2)}"
            @click="check(2)"
            :disabled="isIntervalDisabled(2)">seconde majeure</button>
      </div>
    </div>
    <div class="line">
      <div>
        <button
            :class="{error: isIntervalError(3)}"
            @click="check(3)"
            :disabled="isIntervalDisabled(3)">tierce mineure</button>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(4)}"
            @click="check(4)"
            :disabled="isIntervalDisabled(4)">tierce majeure</button>
      </div>
    </div>
    <div class="line">
      <div></div>
      <div>
        <button
            :class="{error: isIntervalError(5)}"
            @click="check(5)"
            :disabled="isIntervalDisabled(5)">quarte juste</button>
      </div>
    </div>
    <div class="line">
      <div>
        <button
            :class="{error: isIntervalError(6)}"
            @click="check(6)"
            :disabled="isIntervalDisabled(6)">quinte diminuée</button>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(7)}"
            @click="check(7)"
            :disabled="isIntervalDisabled(7)">quinte juste</button>
      </div>
    </div>
    <div class="line">
      <div>
        <button
            :class="{error: isIntervalError(8)}"
            @click="check(8)"
            :disabled="isIntervalDisabled(8)">sixte mineure</button>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(9)}"
            @click="check(9)"
            :disabled="isIntervalDisabled(9)">sixte majeure</button>
      </div>
    </div>
    <div class="line">
      <div>
        <button
            :class="{error: isIntervalError(10)}"
            @click="check(10)"
            :disabled="isIntervalDisabled(10)">septième mineure</button>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(11)}"
            @click="check(11)"
            :disabled="isIntervalDisabled(11)">septième majeure</button>
      </div>
    </div>
    <div class="line">
      <div>
      </div>
      <div>
        <button
            :class="{error: isIntervalError(12)}"
            @click="check(12)"
            :disabled="isIntervalDisabled(12)">octave</button>
      </div>
    </div>
    <div class="line score">
      <div>
      </div>
    </div>
  </div>
  <div class="row ear_training_show" v-else>
    <div class="col col-12" v-if="performanceEvaluator !== null">
      <div class="card">
        <div class="row">
          <div class="col col-12">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span v-if="performanceEvaluator.score.count > 0">
                      {{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %
                    </span>
                  </td>
                  <td>
                    <button @click="play">Replay</button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      :class="{error: isIntervalError(0)}"
                      @click="check(0)"
                      :disabled="isIntervalDisabled(0)">unission</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      :class="{error: isIntervalError(1)}"
                      @click="check(1)"
                      :disabled="isIntervalDisabled(1)">seconde mineure</button>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(2)}"
                      @click="check(2)"
                      :disabled="isIntervalDisabled(2)">seconde majeure</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      :class="{error: isIntervalError(3)}"
                      @click="check(3)"
                      :disabled="isIntervalDisabled(3)">tierce mineure</button>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(4)}"
                      @click="check(4)"
                      :disabled="isIntervalDisabled(4)">tierce majeure</button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      :class="{error: isIntervalError(5)}"
                      @click="check(5)"
                      :disabled="isIntervalDisabled(5)">quarte juste</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      :class="{error: isIntervalError(6)}"
                      @click="check(6)"
                      :disabled="isIntervalDisabled(6)">quinte diminuée</button>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(7)}"
                      @click="check(7)"
                      :disabled="isIntervalDisabled(7)">quinte juste</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      :class="{error: isIntervalError(8)}"
                      @click="check(8)"
                      :disabled="isIntervalDisabled(8)">sixte mineure</button>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(9)}"
                      @click="check(9)"
                      :disabled="isIntervalDisabled(9)">sixte majeure</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      :class="{error: isIntervalError(10)}"
                      @click="check(10)"
                      :disabled="isIntervalDisabled(10)">septième mineure</button>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(11)}"
                      @click="check(11)"
                      :disabled="isIntervalDisabled(11)">septième majeure</button>
                  </td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>
                    <button
                      :class="{error: isIntervalError(12)}"
                      @click="check(12)"
                      :disabled="isIntervalDisabled(12)">octave</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <table>
        <thead>
        <tr>
          <th></th>
          <th v-for="(note, index) in modelValue.notesSelection" :key="index">
            {{ getNote(note).nameUp }} {{ note}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(interval, i2) in modelValue.intervalSelection" :key="i2">
          <th>{{intervalList[interval].name}} {{ interval}}</th>
          <th v-for="(note, index) in modelValue.notesSelection" :key="index">
            {{ performanceEvaluator.getOneStat({note, interval})?.point }}/{{ performanceEvaluator.getOneStat({note, interval})?.count }}
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script>
import Notes from '@/MusicalProcessing/Factories/Notes';
import Interval from '@/MusicalProcessing/Factories/Interval';
import SimpleSynth from '@/core/simpleSynth';
import PerformanceEvaluator from '@/core/PerformanceEvaluator';

export default {
  props: {
    modelValue: {},
    statistiques: {},
  },
  data() {
    return ({
      intervalList: Interval,
      currentInterval: null,
      noteIntervalDuration: 500,
      playNote: null,
      playNoteInterval: null,
      minimumScore: 0.6,
      actualTraining: [],
      indexNextQuestion: null,
      lastTimeKeyed: null,
      baseTimeEqualOne: 3000, // 1 seconde donne un point,
      saveIntervalTime: 1000, // 10 seconde
      saveIntervalInstance: null,
      performanceEvaluator: null,
    })
  },
  methods: {
    getNote(noteIndex) {
      return (Notes[noteIndex]);
    },
    check(interval) {
      const actualNote = this.performanceEvaluator.getActualQuestion().note;
      const checkInterval = this.performanceEvaluator.getAllCombinaisons().find((_) => _.note === actualNote && _.interval === interval);

      if (this.performanceEvaluator.doResponse(checkInterval)) {
        window.setTimeout(() => {
          this.play();
        }, 250);
      }
      const toSave = this.performanceEvaluator.getToSave();
      this.$emit('update:statistiques', toSave);
    },
    play() {
      const actualQuestion = this.performanceEvaluator.getActualQuestion();
      const currentNote = Notes[actualQuestion.note];
      const currentNoteInterval = Notes[actualQuestion.note + actualQuestion.interval];
      const playNote = `${currentNote.nameDown}${currentNote.octave - 2}`;
      const playNoteInterval = `${currentNoteInterval.nameDown}${currentNoteInterval.octave - 2}`;
      SimpleSynth.playNoteName(playNote);
      window.setTimeout(() => {
        SimpleSynth.playNoteName(playNoteInterval);
      }, this.noteIntervalDuration);
    },
    isIntervalError(interval) {
      const actualNote = this.performanceEvaluator.getActualQuestion().note;
      const checkInterval = this.performanceEvaluator.getAllCombinaisons().find((_) => _.note === actualNote && _.interval === interval);
      return (this.performanceEvaluator.errorList.includes(checkInterval));
    },
    isIntervalDisabled(interval) {
      return (!this.performanceEvaluator.getEnabledCombinaisons().some((_) => _.interval === interval));
    },
  },
  beforeUnmount() {
    window.clearInterval(this.saveIntervalInstance);
  },
  async mounted() {
    this.performanceEvaluator = new PerformanceEvaluator({
      note: this.modelValue.notesSelection,
      interval: this.modelValue.intervalSelection,
    },
    this.statistiques
    );
    this.performanceEvaluator.setNewOnError = false;
    this.performanceEvaluator.setNew();
    window.setTimeout(() => {
      this.play();
    }, 250);
  }
};
</script>

<style lang="scss">
.ear_training_show {
  button {
    width: 100%;
    &.error {
      background-color: #ff000047;
    }
  }
  .score {
    font-size: 24px;
    text-align: center;
  }
}
</style>