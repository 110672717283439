<template>
  <div class="vex_flow">
    <div
        ref="vexContainer"
        class="vex_flow_reference"
    >
      <svg v-show="showCursor" :style="{left: cursorPosition + 'px'}" class="cursor-line" height="200" width="2">
        <line x1="0" y1="0" x2="0" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
      </svg>
    </div>
    <button @click="test">test</button>
    <button @click="test2">test2</button>
    <button @click="startPlayback">startPlayback</button>
    <button @click="stopPlayback">stopPlayback</button>
  </div>
</template>

<script>
import Vex from 'vexflow';

export default {
  data() {
    return ({
      VF: Vex.Flow,
      voices: [],
      formatter: null,
      stave: null,
      context: null,
      cursorPosition: 0,
      showCursor: false,
      animationFrameId: null,
    });
  },
  mounted() {
    this.renderMusicNotation();
  },
  methods: {
    renderMusicNotation() {
      const div = this.$refs.vexContainer;
      const renderer = new this.VF.Renderer(div, this.VF.Renderer.Backends.SVG);

      renderer.resize(500, 140);
      this.context = renderer.getContext();
      this.stave = new this.VF.Stave(10, 0, 400);
      this.stave.addClef('treble').addTimeSignature('4/4');
      this.stave.setContext(this.context).draw();
      // this.stave = new this.VF.Stave(100, 0, 400);
      // this.stave.setContext(this.context).draw();
      this.formatter = new this.VF
          .Formatter();
    },
    test() {
      this.resetVoices(); // Appel à une méthode qui réinitialise les voix
      const notes = [
        // w: whole, h: half, q: quarter
        new this.VF.StaveNote({ keys: ['b/4'], duration: 'w' }),
        // new this.VF.StaveNote({ keys: ['b/4'], duration: 'h' }),
        // new this.VF.StaveNote({ keys: ['b/4'], duration: 'q' }).addModifier(new this.VF.Accidental("#"), 0),
        // new this.VF.StaveNote({ keys: ['b/4'], duration: '8' }),
        // new this.VF.StaveNote({ keys: ['b/4'], duration: '16' }),
        // new this.VF.StaveNote({ keys: ['b/4'], duration: '16' }),
        // w: whole, h: half, q: quarter
        new this.VF.StaveNote({ keys: ['b/4'], duration: 'h' }),
        new this.VF.StaveNote({ keys: ['b/4'], duration: 'q' }).addModifier(new this.VF.Accidental("#"), 0),
        new this.VF.StaveNote({ keys: ['b/4'], duration: '8' }),
        new this.VF.StaveNote({ keys: ['b/4'], duration: '16' }),
        new this.VF.StaveNote({ keys: ['b/4'], duration: '16' }),
      ];
      const beams = this.VF.Beam.generateBeams(notes);
      // console.log(test);
      // notes[0].setStyle({ fillStyle: "red", strokeStyle: "green" });
      // notes[1].addAccidental(1, new this.VF.Accidental("#"));
      // Création du faisceau pour lier les notes
      // const beam = new this.VF.Beam([notes[2], notes[3]]);
      const voice = new this.VF.Voice({ num_beats: 8, beat_value: 4 });
      voice.addTickables(notes);
      this.voices = [voice];

      this.redraw();
      beams.forEach((_) => _.setContext(this.context).draw());
      // beam
      // Voir

    },
    test2() {
      this.resetVoices(); // Réinitialisez les voix avant d'ajouter de nouvelles notes
      const notes = [
        new this.VF.StaveNote({ keys: ['g/4'], duration: 'q' }),
        new this.VF.StaveNote({ keys: ['a/4'], duration: 'q' }),
        new this.VF.StaveNote({ keys: ['b/4'], duration: 'q' }),
        new this.VF.StaveNote({ keys: ['c/5'], duration: 'q' }) // Assurez-vous que le total des durées de notes est conforme à 4/4
      ];

      const voice = new this.VF.Voice({ num_beats: 4, beat_value: 4 });
      voice.addTickables(notes);
      this.voices = [voice];

      this.redraw();
    },
    resetVoices() {
      this.voices = []; // Nettoie les voix existantes
      this.formatter = new this.VF.Formatter(); // Réinitialise le formateur
    },
    redraw() {
      this.context.clear(); // Efface le contexte pour redessiner
      this.stave.setContext(this.context).draw(); // Redessine la portée

      this.formatter.joinVoices(this.voices) // Rejoindre à nouveau les voix
          .format(this.voices, 300); // Augmenter la largeur si nécessaire

      this.voices.forEach(voice => {
        voice.draw(this.context, this.stave);
      });
    },
    startPlayback() {
      this.showCursor = true;
      this.extractTickPositions();
      this.animateCursor();
    },
    extractTickPositions() {
      let tickContexts = this.formatter.tickContexts.array;
      this.timesX = tickContexts.map(context => context.getX());
    },
    animateCursor() {
      const durationPerTick = 1000; // ms, ajustez selon le tempo
      let tickIndex = 0;

      const moveCursor = () => {
        if (tickIndex < this.timesX.length) {
          this.cursorPosition = this.timesX[tickIndex];
          this.animationFrameId = setTimeout(moveCursor, durationPerTick);
          tickIndex++;
        } else {
          this.stopPlayback();
        }
      };

      moveCursor();
    },
    stopPlayback() {
      clearTimeout(this.animationFrameId);
      this.showCursor = false;
      this.cursorPosition = 0;
    }
  },
}
</script>

<style lang="scss">
.vex_flow {
  background-color: #cccccc;
  .vex_flow_reference {
    width: 100%;
    border: 1px solid black;
    position: relative;
    width: 100%;
    height: 140px;
    border: 1px solid black;
  }
  .cursor-line {
    position: absolute;
    top: 0;
  }
}
</style>

