<template>
  <slot
      :data="localData"
      :oldData="data"
      :save="save"
      :load="load"
      :del="del"
      :formInput="FormInput"
      :changed="getChanged"
      v-if="localData !== null"
  >
    <div class="row">
      <div v-if="errors.length">
        {{ errors }}
      </div>
      <div
        v-for="col in schema"
        class="col col-12"
        :key="col.column"
      >
        <ColumnProxy
            v-model="localData[col.column_name]"
            :field="col"
        ></ColumnProxy>
      </div>
      <div class="col col-12">
        <button @click="save">SAVE</button>
      </div>
      <div class="col col-12">
        <button @click="del">DEL</button>
      </div>
    </div>
  </slot>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';
import FormInput from '@/components/FormInput/index';
import ColumnProxy from '@/components/ColumnProxy.vue';

export default {
  emits: ['action'],
  components: {
    ColumnProxy,
  },
  data() {
    return ({
      data: {},
      FormInput,
      localData: {},
      errors: [],
    })
  },
  props: {
    uri: {
      type: String,
      required: true,
    },
    entity_id: {},
    baseData: {},
    fieldList: {},
    schema: {
      type: Array,
      default() {
        return ([]);
      }
    },
  },
  computed: {
    getBaseData() {
      return (this.baseData);
    },
    getPreparedSchema() {
      if (this.fieldList === undefined) {
        return (this.schema);
      }
      const entries = this.fieldList.map((_) => [_, this.schema[_]]);
      return (Object.fromEntries(entries));
    },
    getUri() {
      return (this.uri);
    },
    getEntityId() {
      return (this.entity_id);
    },
    getChanged() {
      return (Object.entries(this.data).reduce((acc, [key, elm]) => {
        if (JSON.stringify(elm) != JSON.stringify(this.localData[key])) {
          acc.push(key);
        }
        return (acc);
      }, []));
    },
  },
  methods: {
    async save() {
      this.errors = [];
      try {
        if (this.entity_id !== undefined && this.entity_id !== null) {
          await axiosInstance.patch(`${this.uri}/${this.entity_id}`,
              this.localData
          );
        } else {
          await axiosInstance.post(this.uri,
              this.localData
          );
        }
        this.$emit('action', 'save');
        if (this.baseData === undefined) {
          this.load();
        }
      } catch (e) {
        this.errors = e.response?.data?.errors;
      }
    },
    async del() {
      if (this.entity_id !== undefined && this.entity_id !== null) {
        await axiosInstance.delete(`${this.uri}/${this.entity_id}`);
        this.$emit('action', 'del');
      }
    },
    async load() {
      if (this.baseData !== undefined) {
        this.data = this.baseData
      } else if (this.entity_id !== undefined && this.entity_id !== null) {
        this.data = (await axiosInstance.get(this.uri)).data[0];
      } else if (this.schema !== undefined && this.schema !== null) {
        this.data = Object.fromEntries(this.schema.map((_) => [_.column_name, null]));
      }
      this.localData = { ...this.data };
    },
  },
  watch: {
    getBaseData() {
      this.data = this.baseData;
      this.localData = { ...this.data };
    },
    getUri() {
      this.load();
    },
    getEntityId() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
};
</script>
