<template>
  <table class="table_data">
    <thead>
      <tr>
        <th v-for="col in getPreparedSchema" :key="col.column_name">{{ col.column_name }}</th>
      </tr>
    </thead>
    <tbody v-if='schema !== undefined'>
    <tr>
      <CRUD
          :uri="uri"
          :schema="schema"
          @action='load'
      >
        <template v-slot:default="{data, save, changed}">
          <td v-for="col in getPreparedSchema" :key="`${col.column_name}`">
            <ColumnProxy
                v-model="data[col.column_name]"
                :field="col"
                :class="{changed}"
            >
            </ColumnProxy>
          </td>
          <td class="actions">
            <div>
              <div>
                <button @click="save">A</button>
              </div>
              <div>
              </div>
            </div>
          </td>
        </template>
      </CRUD>
    </tr>
    <tr v-for="(element, key) in dataList" :key="key" >
      <CRUD
          :uri="uri"
          :entity_id="element.id"
          :base-data="element"
          :schema="schema"
          @action='load'
      >
        <template v-slot:default="{data, save, del, changed}">
          <td v-for="col in getPreparedSchema" :key="`${col.column_name}-${key}`">
            <ColumnProxy
                v-model="data[col.column_name]"
                :instance="data"
                :field="col"
                :class="{changed}"
            >
            </ColumnProxy>
          </td>
          <td class="actions">
            <div>
              <div>
                <button @click="save">S</button>
              </div>
              <div>
                <button @click="del">D</button>
              </div>
              <div>
                <button @click="load">L</button>
              </div>
            </div>
          </td>
        </template>
      </CRUD>
    </tr>
    </tbody>
  </table>
</template>

<script>
import axiosInstance from '@/core/axiosInstance';
import CRUD from '@/components/CRUD.vue';
import ColumnProxy from '@/components/ColumnProxy.vue';
export default {
  components: {
    ColumnProxy,
    CRUD,
  },
  data() {
    return ({
      dataList: [],
    });
  },
  props: {
    uri: {},
    fieldList: {},
    schema: {},
  },
  computed: {
    getPreparedSchema() {
      if (this.fieldList === undefined) {
        return (this.schema);
      }
      const entries = this.fieldList.map((_) => [_, this.schema[_]]);
      return (Object.fromEntries(entries));
    },
  },
  methods: {
    async load() {
      const {data} = await axiosInstance.get(this.uri);
      this.dataList = data;
    },
  },
  async mounted() {
    await this.load();
  }
}
</script>

<style lang="scss">
.table_data {
  .actions > div {
    display: flex;
  }
  td.actions button {
    height: 100%;
    padding: 0 5px;
    min-width: 30px;
  }

  input {
    height: 100%;
    padding: 0 12px;
    border: none;
    &:hover {
      background-color: #b8803955;
    }
    background-color: #fff1;
  }
  tr {

    border-bottom: 1px solid #b88039;
    td {
      height: 30px;
      .label {
        display: none;
      }
      > div {
        height: 100%;
      }
    }
  }
}
</style>