<template>
  <div
    class="learn_guitar_neck"
    v-if="setting !== undefined"
    :class="{
      isVertical: isVertical,
    }"
  >
    <GuitarNeck
      @stringClicked="stringClicked"
      :numberOfFrets="12"
      :surbrillance="[toFind.string]"
      :neck-tuning="setting.neckTuning"
      @isVertical="isVertical = $event"
    ></GuitarNeck>
    <FullScreenShow v-if="generatedNote !== null">
      <span :class="{noteError: error}">
<!--        <b>{{ toFind.string }}</b>-->
        {{ generatedNote[generatedRepresentation] }}
      </span>
    </FullScreenShow>
  </div>
</template>

<script>
import GuitarNeck from '@/components/GuitarNeck.vue';
import FullScreenShow from '@/components/FullScreenShow.vue';
import NeckTuning from '@/MusicalProcessing/Factories/NeckTuning';
import getRandArrayElement from '@/Generators/getRandArrayElement';
import Interval from '@/MusicalProcessing/Factories/Interval';
import Notes from '@/MusicalProcessing/Factories/Notes';
import soundFont from '@/core/soundFont';

/*
  Refacto :
  Separation des responsabilite:
    Configuration
    Interface Exercice
    Processing de l'exercice
  Guitare Neck
    + number of strings
  Exo
    + Chord Tuning
*/

export default {
  components: {
    GuitarNeck,
    FullScreenShow,
  },
  data() {
    return ({
      isVertical: null,
      config: {
        availableString: [
          // 6, 5, 4, 3, 2, 1,
          1, 2, 3, 4, 5, 6,
        ],
        NeckTuning: NeckTuning.StandardTuning.notes,
        Intervals: [
          Interval[0],
          Interval[1],
          Interval[2],
          Interval[3],
          Interval[4],
          Interval[5],
          Interval[6],
          Interval[7],
          Interval[8],
          Interval[9],
          Interval[10],
          Interval[11],
        ],
      },
      generatedString: null,
      generatedNote: null,
      generatedInterval: null,
      generatedRepresentation: null,
      error: false,
      noteRepersentation: [
        'nameUp',
        'nameDown',
      ],
      playSoundFont: null,
      pianoSoundFont: null,
      lastPlay: null,
      toFind: {
        string: null,
        fret: null,
      },
    });
  },
  props: {
    setting: {
      type: Object,
      // default:
    },
  },
  methods: {
    stringClicked({ stringId, fretId }) {
      const baseNote = this.setting.neckTuning.notes[stringId - 1];

      const note = Notes[baseNote.midiNote + fretId];
      if (this.lastPlay) {
        this.lastPlay.stop();
      }
      const playNote = `${note.nameDown}${note.octave - 2}`;
      this.lastPlay = this.playSoundFont.play(playNote);
      if (
        stringId !== this.toFind.string
        || fretId !== this.toFind.fret) {
        this.error = true;
        return;
      }
      this.error = false;
      window.setTimeout(
        this.changeNote,
        500,
      );
    },
    changeNote() {
      let tmp = getRandArrayElement(this.setting.selection);
      if (this.setting.selection.length > 1) {
        while (tmp.string === this.toFind.string && tmp.fret === this.toFind.fret) {
          tmp = getRandArrayElement(this.setting.selection);
        }
      }
      const { string, fret } = tmp;
      this.toFind = { string, fret };
      const stringNote = this.setting.neckTuning.notes[string - 1];
      const generatedNote = Notes[stringNote.midiNote + fret];
      const interval = Interval[fret];
      const noteRepersentation = getRandArrayElement(this.noteRepersentation);

      this.generatedNote = generatedNote;
      this.generatedInterval = interval;
      this.generatedRepresentation = noteRepersentation;

      const playNote = `${generatedNote.nameDown}${generatedNote.octave - 2}`;
      if (this.lastPlay) {
        this.lastPlay.stop();
      }
      this.lastPlay = this.pianoSoundFont.play(playNote);
    },
  },
  computed: {
    getPlaySound() {
      return (this.setting.playSound);
    },
  },
  watch: {
    async getPlaySound(sound) {
      this.playSoundFont = await soundFont(sound);
    },
  },
  async mounted() {
    this.pianoSoundFont = await soundFont('acoustic_grand_piano');
    if (this.setting.playSound !== undefined) {
      this.playSoundFont = await soundFont(this.setting.playSound);
    }
    // this.playSoundFont = await soundFont('acoustic_guitar_nylon');
    this.changeNote();
  },
};
</script>

<style>
.noteError {
  color:red;
}

.learn_guitar_neck {
  background-color: white;
  height:100%;
  width: 100%;
  //width: 100vw;
  //height: 50vh;
  &.isVertical .full-screen-show > div {
    transform: rotate(90deg);
  }
}
</style>
