<template>
  <div class="interval_semi_tons" v-if="performanceEvaluator !== null">
    <div class="line score">
      <div>
        <span v-if="performanceEvaluator.score.count > 0">
          {{ Math.round(10000 * performanceEvaluator.getGlobalScore()) / 100 }} %
          <span class="small_score">
            ({{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %)
          </span>
        </span>
      </div>
      <div>
        {{ intervalList[performanceEvaluator.getActualQuestion()?.intervals][modelValue.showLabel] }}
      </div>
    </div>
    <div v-for="_ in sortedIntervals" :key="_" class="line">
      <div>
        <button
            :class="{error: isNoteError(_)}"
            @click="check(_)"
        >
          {{ intervalList[_][modelValue.requestedLabel] }}
        </button>
      </div>
    </div>
    <div class="line score">
      <div>
        <!--        <span v-if="performanceEvaluator.score.count > 0">-->
        <!--          {{ Math.round(100 * performanceEvaluator.score.point / performanceEvaluator.score.count) }} %-->
        <!--        </span>-->
      </div>
      <!--      <div>-->
      <!--        {{ getNote(performanceEvaluator.getActualQuestion()?.note).nameUpFr }}-->
      <!--        <span-->
      <!--            v-if="performanceEvaluator.getActualQuestion()?.semitone >= 12"-->
      <!--        >-->
      <!--          +-->
      <!--        </span>{{ performanceEvaluator.getActualQuestion()?.semitone - 12 }}-->
      <!--        / 2-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import Notes from '@/MusicalProcessing/Factories/Notes';
import Interval from '@/MusicalProcessing/Factories/Interval';
import SimpleSynth from '@/core/simpleSynth';
import PerformanceEvaluator from '@/core/PerformanceEvaluator';
// import {toRaw} from 'vue';
// import Background from '@/components/Background.vue';

export default {
  // components: {Background},
  props: {
    modelValue: {},
    statistiques: {},
  },
  data() {
    return ({
      pointPotential: null,
      response: '',
      exerciceConfig: {
        intervals: [],
      },
      notePresentation: [],
      intervalList: Interval,
      currentInterval: null,
      noteIntervalDuration: 500,
      playNote: null,
      playNoteInterval: null,
      minimumScore: 0.6,
      actualTraining: [],
      indexNextQuestion: null,
      lastTimeKeyed: null,
      baseTimeEqualOne: 125, // 1 seconde donne un point,
      saveIntervalTime: 1000, // 10 seconde
      saveIntervalInstance: null,
      performanceEvaluator: null,
    })
  },
  methods: {
    getNote(noteIndex) {
      return (Notes[noteIndex]);
    },
    check(intervals) {
      const response = {
        intervals,
      }
      this.performanceEvaluator.doResponse(response);
      const toSave = this.performanceEvaluator.getToSave();
      this.$emit('update:statistiques', toSave);
      this.test();
    },
    test() {
      // setTimeout((_) => {
      //   const toSet = {
      //     note:this.performanceEvaluator.getActualQuestion().note,
      //     semitone:this.performanceEvaluator.getActualQuestion().semitone,
      //   };
      //   this.check(toSet.note + toSet.semitone);
      // }, 300);
    },
    play() {
      const actualQuestion = this.performanceEvaluator.getActualQuestion();
      const currentNote = Notes[actualQuestion.note];
      const currentNoteInterval = Notes[actualQuestion.note + actualQuestion.interval];
      const playNote = `${currentNote.nameDown}${currentNote.octave - 2}`;
      const playNoteInterval = `${currentNoteInterval.nameDown}${currentNoteInterval.octave - 2}`;
      SimpleSynth.playNoteName(playNote);
      window.setTimeout(() => {
        SimpleSynth.playNoteName(playNoteInterval);
      }, this.noteIntervalDuration);
    },
    isNoteError(intervals) {
      return (this.performanceEvaluator.errorList.some((_) => _.intervals === intervals));
    },
    isIntervalDisabled(interval) {
      return (!this.performanceEvaluator.getEnabledCombinaisons().some((_) => _.interval === interval));
    },
  },
  beforeUnmount() {
    window.clearInterval(this.saveIntervalInstance);
  },
  computed: {
    sortedIntervals() {
      return ([...this.modelValue.intervalSelection].sort((a, b) => a - b));
    },
  },
  async mounted() {
    this.exerciceConfig.intervals = this.modelValue.intervalSelection;
    this.performanceEvaluator = new PerformanceEvaluator(this.exerciceConfig, this.statistiques);
    this.performanceEvaluator.baseTimeEqualOne = 1500;
    this.performanceEvaluator.setNew();
  }
};
</script>

<style lang="scss">
.interval_semi_tons {
  display:flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  height: 100vh - env(safe-area-inset-bottom);
  > div.line {
    font-weight: bold;
    &.score {
      background-color: black;
      color: white;
    }
    display:flex;
    width: 100%;
    justify-content: stretch;
    flex: 1;
    > div {
      flex: 1;
      font-size: 22px;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &.error {
          background-color: #ff000047;
        }
        border-radius: 0;
      }
      align-items: center;
      justify-content: center;
      display:flex;
    }
  }
  .score {
    font-size: 24px;
    text-align: center;
    .small_score {
      font-size:0.7em;
      font-style: italic;
      font-weight: normal;
    }
  }
}
</style>