const frTranslation = {
  C: 'Do',
  D: 'Ré',
  E: 'Mi',
  F: 'Fa',
  G: 'Sol',
  A: 'La',
  B: 'Si',
}

function noteNameTranslate(noteName) {
  let rt = noteName;
  Object.keys(frTranslation).forEach((key) => {
    if (noteName.indexOf(key) !== -1)
      rt = rt.replace(key, frTranslation[key]);
  });
  return (rt);
}

export default class {

  constructor(
    midiNote,
    nameUp,
    nameDown,
    octave,
    frequency,
    altered,
    lineUp,
    lineDown,
  ) {
    this.midiNote = midiNote;
    this.nameUp = nameUp;
    this.nameDown = nameDown;
    this.nameUpFr = noteNameTranslate(nameUp);
    this.nameDownFr = noteNameTranslate(nameDown);
    if (altered) {
      this.nameFr = `${this.nameUpFr}/${this.nameDownFr}`;
      this.name = `${this.nameUp}/${this.nameDown}`;
    } else {
      this.nameFr = this.nameUpFr;
      this.name = this.nameUp;
    }
    this.frequency = frequency;
    this.octave = octave;
    this.altered = altered;
    this.lineUp = lineUp;
    this.lineDown = lineDown;
  }
}
