<template>
  <div>
    <NeckTuningSelector
      v-model="config.neckTuning"
      @input="change"
    ></NeckTuningSelector>

    <SoundFondSelect
      v-model="config.playSound"
      @input="change"
    ></SoundFondSelect>
    <button
      v-if="config.neckTuning !== undefined"
      @click="selectAll"
    >
      Tout selectionner
    </button>
    <GuitarNeck
      style="min-height: 13vw;"
      v-model="config.selection"
      :neck-tuning="config.neckTuning"
      showNoteName
      :select-mode="true"
    ></GuitarNeck>
  </div>
</template>

<script>
/*
  On devrait juste Choisir l'accordage et les notes du manche
 */
import NeckTuningSelector from '@/MusicalProcessing/Components/NeckTuningSelector.vue';
import GuitarNeck from '@/components/GuitarNeck.vue';
import SoundFondSelect from '@/components/SoundFondSelect.vue';

export default {
  components: {
    GuitarNeck,
    NeckTuningSelector,
    SoundFondSelect,
  },
  data() {
    return ({
      config: {
        selection: [],
        neckTuning: null,
        playSound: 'acoustic_guitar_nylon',
        nbrFrets: 'acoustic_guitar_nylon',
      },
    });
  },
  props: {
    modelValue: {},
  },
  methods: {
    selectAll() {
      this.config.selection = [];

      for (let string = 0; string < this.config.neckTuning.notes.length; string += 1) {
        for (let fret = 0; fret < 12; fret += 1) {
          this.config.selection.push({ string: string + 1, fret });
        }
      }
    },
    change() {
      this.$emit('update:modelValue', this.config);
    },
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue(value) {
      this.config = value;
    },
  },
  mounted() {
    if (this.modelValue !== undefined) {
      this.change();
    }
  },
};
</script>
