<template>
  <div class="deconnect" @click="deconnect">
    <span>Decconexion</span>
  </div>
</template>

<script>
import Parameters from '@/core/Parameters';

export default {
  data() {
    return ({
      SERVER_URL: Parameters.SERVER_URL
    });
  },
  methods: {
    deconnect() {
      localStorage.removeItem('userToken');
      this.$router.replace({name: 'Login'});
    },
  },
};
</script>

<style>
.deconnect {
  position:fixed;
  top: 0;
  left: 0;
  cursor:pointer;
  font-weight: bold;
}
</style>