<template>
  <div>
    <NoteSelector
      v-model="localValue.notesSelection"
      @update:modelValue="set('notesSelection', $event)"
    ></NoteSelector>
    <br>
    <IntervalSelector
      v-model="localValue.intervalSelection"
      @update:modelValue="set('intervalSelection', $event)"
    ></IntervalSelector>
    <br>
    <SoundFondSelect
        :model-value="localValue.playSound"
        @update:modelValue="set('playSound', $event)"
    ></SoundFondSelect>
  </div>
</template>

<script>
import SoundFondSelect from '@/components/SoundFondSelect.vue';
import NoteSelector from '@/MusicalProcessing/Components/NoteSelector.vue';
import IntervalSelector from '@/MusicalProcessing/Components/IntervalSelector.vue';

export default {
  emits: ['update:modelValue'],
  data() {
    return ({
      localValue: {
        intervalSelection: [],
        notesSelection: [],
        playSound: null,
      }
    });
  },
  components: {
    SoundFondSelect,
    IntervalSelector,
    NoteSelector,
  },
  props: {
    modelValue: {},
  },
  computed: {
    getModelValue() {
      return (this.modelValue);
    },
  },
  watch: {
    getModelValue() {
      this.reset();
    },
  },
  methods: {
    set(keyName, value) {
      const toSend = {
        ...this.localValue,
        [keyName]: value,
      };
      this.$emit('update:modelValue', toSend);
    },
    reset() {
      const reset = {
        ...this.localValue,
        ...this.modelValue,
      };
      this.localValue = reset;
    }
  },
  mounted() {
    this.reset();
  },
};
</script>
