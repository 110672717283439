<template>
  <div class="music_clock">
    <Background :currentIndex="17"/>
    <svg
        viewBox="0 0 300 300"
        xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="150" cy="150" :r="outerRadius" stroke="black" stroke-width="2" class="outer_circle"/>
      <circle cx="150" cy="150" :r="innerRadius" stroke="black" stroke-width="2"  class="inner_circle"/>
      <g
          class="note_zone"
          :class="{
            altered: note.altered,
            error: errors.includes(note.midiNote)
          }"
          v-for="(note, index) in getNotes"
          :key="index"
          @click="handleNoteClick(note)"
      >
        <path :d="sectorPath(index)" class="click_zone"/>
        <text
            :x="calculateTextPosition(index).x"
            :y="calculateTextPosition(index).y"
            font-family="Arial"
            font-size="12"
            text-anchor="middle"
            alignment-baseline="middle"
            v-if="showNotesName || (showNotesNameErrors && errors.includes(note.midiNote))"
        >
          {{ note[noteLabel] }}
        </text>
      </g>
      <circle cx="150" cy="150" :r="outerRadius + 0.5" stroke-width="1" class="outer_circle_2"/>
    </svg>
    <div class="center_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Notes from '@/MusicalProcessing/Factories/Notes';
import Background from '@/components/Background.vue';
export default {
  name: 'MusicClock',
  components: {Background},
  data() {
    return {
      notes: Notes,
      outerRadius: 149,
      innerRadius: 50
    };
  },
  props: {
    score: {},
    showNotesName: {
      type: Boolean,
      default: true,
    },
    showNotesNameErrors: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default() {
        return ([]);
      },
    },
    noteLabel: {
      type: String,
      default: 'nameUpFr',
    },
    noteOctave: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    getNotes() {
      return (this.notes.filter((_) => _.octave === this.noteOctave));
    },
  },
  methods: {
    getScore(note) {
      if (this.score && this.score[note]) {
        return (this.score[note]);
      }
      return (0);
    },
    calculatePosition(index, radius) {
      const angle = 2 * Math.PI * (index - 3) / 12;
      return {
        x: 150 + radius * Math.cos(angle),
        y: 150 + radius * Math.sin(angle)
      };
    },
    calculateTextPosition(index) {
      return this.calculatePosition(index, (this.outerRadius + this.innerRadius) / 2);
    },
    sectorPath(index, percent = 1) {
      const startAngle = 2 * Math.PI * (index - 3.5) / 12;
      const endAngle = 2 * Math.PI * (index - 2.5) / 12;
      const outer = (Math.max(percent, 0) * (this.outerRadius - this.innerRadius)) + this.innerRadius;
      const startXOuter = 150 + outer * Math.cos(startAngle);
      const startYOuter = 150 + outer * Math.sin(startAngle);
      const endXOuter = 150 + outer * Math.cos(endAngle);
      const endYOuter = 150 + outer * Math.sin(endAngle);
      const startXInner = 150 + this.innerRadius * Math.cos(startAngle);
      const startYInner = 150 + this.innerRadius * Math.sin(startAngle);
      const endXInner = 150 + this.innerRadius * Math.cos(endAngle);
      const endYInner = 150 + this.innerRadius * Math.sin(endAngle);
      const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;
      return `M ${startXOuter} ${startYOuter} A ${outer} ${outer} 0 ${largeArcFlag} 1 ${endXOuter} ${endYOuter} L ${endXInner} ${endYInner} A ${this.innerRadius} ${this.innerRadius} 0 ${largeArcFlag} 0 ${startXInner} ${startYInner} Z`;
    },
    handleNoteClick(note) {
      if (this.errors.includes(note.midiNote)) {
        return
      }
      this.$emit('note', note);
    },
  }
}
</script>

<style lang="scss">
$music_clock_note_color: #ffffff50;
$music_clock_note_color_altered: #00000020;
$music_clock_note_color_hover: #b4b4ff40;
$music_clock_note_color_error: #940c0c6b;
$music_clock_note_score_color: #00800010;
.music_clock {
  .background_container {
    opacity: 0.15;
    filter:blur(4px);
  }
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .center_content {
    pointer-events: none;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  svg {
    circle.outer_circle {
      fill: $music_clock_note_color;
    }
    circle.outer_circle_2 {
      pointer-events: none;
      stroke: #807ead;
      fill: transparent;
    }
    circle.inner_circle {
      fill: #000000b0;
    }

    width: 100%;
    height: 100%;
    .note_zone {
      stroke: black;
      cursor:pointer;
      fill: transparent;

      path {
        &.path_score {
          fill: $music_clock_note_score_color;
          stroke:none;
        }
        &.click_zone {
          stroke-width: 0.5px;
          &:hover {
            fill: $music_clock_note_color_hover;
          }
        }
      }
      text {
        pointer-events: none;
        fill: white;
        stroke:none;
        font-weight: bold;
      }
      &.altered > text {
        fill: black;
      }
      &.error > path {
        fill: $music_clock_note_color_error;
        cursor: initial;
        &.click_zone:hover {
          fill: $music_clock_note_color_error;
        }
      }
    }
  }
}
</style>
