<template>
  <main>
    <section class="container">
      <div class="row">
        <div class="col col-12">
          <div class="row card">
            <div class="col col-12">
              <router-link
                :to="{
                  name: 'CrudElement',
                  params: { tableName: 'learning_node' }
                }"
              >
                learning_node
              </router-link>
            </div>
            <div class="col col-12">
              <router-link
                  :to="{
                  name: 'CrudElement',
                  params: { tableName: 'learning_node_exercice' }
                }"
              >
                learning_node_exercice
              </router-link>
            </div>
            <div class="col col-12">
              <router-link
                  :to="{
                  name: 'CrudElement',
                  params: { tableName: 'learning_node_exercice_performed' }
                }"
              >
                learning_node_exercice_performed
              </router-link>
            </div>
            <div class="col col-12">
              <router-link
                  :to="{
                  name: 'CrudElement',
                  params: { tableName: 'learning_node_unlocking_dependance' }
                }"
              >
                learning_node_unlocking_dependance
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  components: {},
  data() {
    return ({
      schema: null,
    })
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
}
</script>