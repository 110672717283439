<template>
  <MusicClock/>
</template>

<script>
import MusicClock from '@/components/MusicClock.vue';

export default {
  components: {
    MusicClock,
  },
};
</script>
