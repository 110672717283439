<template>
  <main>
    <section class="container">
      <div class="login_page">
        <h1>Thunder strum</h1>
        <br>
        <GoogleAuth></GoogleAuth>
      </div>
    </section>
  </main>
</template>

<script>
// import TableData from '@/components/TableData.vue';
import GoogleAuth from '@/components/GoogleAuth.vue';
export default {
  components: {
    // TableData,
    GoogleAuth,
  },
  data() {
    return ({
    })
  },
  methods: {
  },
}
</script>

<style lang="scss">
.login_page {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  height: 100vh;
}
</style>